import React, { useContext, useState } from 'react';
import arrowLeft from 'assets/images/arrow-left-gray.svg';
import styles from './CustomNameDetails.module.scss';
import { ToastTypes } from 'enum/enum';
import CustomNameDetailLeft from './CustomNameDetailLeft';
import CustomNameDetailRight from './CustomNameDetailRight';
import { exportScanData } from 'services/fileUploadServices';
import { ToastContext } from 'context/Toast';
import { AuthContextInterface, ToastInterface } from 'interface/interface';
import { handleErrorMessage } from 'utils/utils';
import { AuthContext } from 'context/AuthContext';
import { ROUTES } from 'constants/constants';
import { useNavigate } from 'react-router-dom';
import NoDataPlaceHolder from 'component/noDataPlaceholder/NoPlaceHolder';
import CustomContext from 'context/CustomContext';

const CustomNameDetails = () => {
  const { selectedValue, getCustomScans } = useContext(CustomContext);
  const [showMore, setShowMore] = useState<boolean>(false);
  const { callToast } = useContext(ToastContext) as ToastInterface;
  const [buttonDisabled, setButtonDisabled] = useState<boolean>(false);
  const { userDetails } = useContext(AuthContext) as AuthContextInterface;
  const navigate = useNavigate();

  const handleExportScanData = async () => {
    setButtonDisabled(true);
    try {
      const res = await exportScanData(selectedValue?.id, userDetails?.email);
      callToast(ToastTypes.SUCCESS, res?.data?.message);
    } catch (error) {
      callToast(ToastTypes.ERROR, handleErrorMessage(error));
    } finally {
      setButtonDisabled(false);
    }
  };
  const handleBack = () => {
    navigate(ROUTES.CUSTOM);
    getCustomScans();
  };

  return (
    <div className={` ${styles.custom}`}>
      <div className={`dflex alignCenter justifySpaceBetween ${styles.custom__header}`} onClick={handleBack}>
        <div role="cursor" className={`dflex alignCenter  ${styles.back}`}>
          <span>
            <img src={arrowLeft} alt="Swan" />
          </span>{' '}
          Back
        </div>
      </div>

      <div className={`dflex ${styles.custom__info}`}>
        {Object.keys(selectedValue)?.length > 0 ? (
          <>
            <CustomNameDetailLeft handleExportScanData={handleExportScanData} buttonDisabled={buttonDisabled} />
            <CustomNameDetailRight showMore={showMore} setShowMore={setShowMore} />
          </>
        ) : (
          <div className={`dflex alignCenter justifyCenter w-full ${styles.customNoData}`}>
            <NoDataPlaceHolder />
          </div>
        )}
      </div>
    </div>
  );
};

export default CustomNameDetails;
