/* eslint-disable @typescript-eslint/no-unused-vars */
import { FileUploadData, ToastInterface } from 'interface/interface';
import React, { useEffect, useRef, useState } from 'react';
import { CircularProgressbar } from 'react-circular-progressbar';
import styles from './File.module.scss';
import filePic from 'assets/images/file.svg';
import check from 'assets/images/check-circle.svg';
import close from 'assets/images/x-close-dark.svg';
import trash from 'assets/images/delete.svg';

function fileNameShorten(fileName: string) {
  if (fileName?.length > 10) {
    return fileName?.slice(0, 10) + '...';
  }
  return fileName;
}
interface FilesProps {
  data: FileUploadData;
  fileFilter?: (a: string) => void;
  handleUpdateFiles?: any;
  sId?: any;
  handleDeleteFile?: (id: string) => Promise<void>;
}

const File = ({ data, fileFilter, handleDeleteFile }: FilesProps) => {
  const { uploaded, file, showProgress, showUploading = false, showCross = false, showDelete = false, id, value } = data;
  const [uploadedValue, setUploadedValue] = useState<number>(value ?? 0);
  const timerId = useRef<NodeJS.Timer>();
  const handleProgress = () => {
    if (uploaded) {
      clearInterval(timerId.current);
      setUploadedValue(100);
    } else {
      clearInterval(timerId.current);
      timerId.current = setInterval(() => {
        setUploadedValue((prev) => prev + 10);
      }, 600);
    }
  };

  useEffect(() => {
    if (showProgress) {
      handleProgress();
    }
    return () => {
      clearInterval(timerId.current);
    };
  }, [uploaded, showProgress]);
  useEffect(() => {
    if (uploadedValue === 100) {
      clearInterval(timerId.current);
    }
  }, [uploadedValue]);
  useEffect(() => {
    if (uploadedValue === 100) {
      setUploadedValue(0);
    }
  }, [showDelete]);

  return (
    <div className={`${styles.uploaded__box} flex`}>
      <figcaption className="flex">
        <figure>
          <img src={filePic} alt="file" />
        </figure>
        <figcaption>
          <h5>{fileNameShorten(file?.name)}</h5>
          <p>
            {Math.round(file?.size / 1000)} KB {showUploading && `${uploadedValue} %`} {showProgress && (uploadedValue === 100 ? 'uploaded' : 'uploading')}
          </p>
        </figcaption>
      </figcaption>
      <figure>
        {uploadedValue === 100 || showDelete ? (
          <>
            <div className={`${styles.tick}`}>
              <img src={check} alt="check" />
            </div>
          </>
        ) : (
          showProgress && <CircularProgressbar strokeWidth={12} value={uploadedValue} />
        )}
        {/* {uploaded && showCross && <img src={close} alt="close" onClick={() => fileFilter && fileFilter(id)} />}  */}
      </figure>
      <figure>
        {uploaded && showDelete && (
          <>
            <div className={`${styles.tick}`}>
              <img src={trash} alt="trash" onClick={() => handleDeleteFile?.(id)} />
            </div>
          </>
        )}
        {uploadedValue === 100 && showCross && <img src={close} alt="close" onClick={() => fileFilter?.(data?.id)} />}
      </figure>
    </div>
  );
};

export default File;
