import React from 'react';
import { MenuItem, Select, SelectChangeEvent } from '@mui/material';
import style from './CustomDropdown.module.scss';
import { GroupData } from 'interface/interface';
import { NONE } from 'constants/constants';
export interface CustomDropdownProps {
  optionList: any[];
  bottomMargin?: boolean;
  handleOnChange: (e: SelectChangeEvent<any>) => void;
  value: string | number | GroupData;
  text?: string;
}
const CustomDropdown = ({ optionList, bottomMargin = true, value, handleOnChange, text }: CustomDropdownProps) => {
  return (
    <div className={`${style.selectDropdown} ${!bottomMargin && style.noMargin}`}>
      <Select
        fullWidth={true}
        value={value}
        onChange={(e: SelectChangeEvent<any>) => {
          handleOnChange(e);
        }}
      >
        {value === NONE && (
          <MenuItem autoFocus value={NONE}>
            {text}
          </MenuItem>
        )}
        {optionList?.map((el: any, index: number) => (
          <MenuItem key={index} value={el}>
            {el?.name ?? el}
          </MenuItem>
        ))}
      </Select>
    </div>
  );
};
export default CustomDropdown;
