import { GroupData } from 'interface/interface';
import React from 'react';
import style from './GroupList.module.scss';
import GroupCard from 'component/groupCard/GroupCard';
import { ModalContentType } from 'enum/enum';
import NoDataPlaceHolder from 'component/noDataPlaceholder/NoPlaceHolder';

interface GroupListProps {
  groups: GroupData[];
  handleTabsChange: (event: React.SyntheticEvent, newValue: string, id?: string) => void;
  handleShowModal: (a?: ModalContentType, id?: string) => void;
  buttonDisabled: boolean;
}

const GroupList = ({ groups, handleTabsChange, handleShowModal, buttonDisabled }: GroupListProps) => {
  return (
    <div className={style.groupBlock}>
      <div className={`${style.groupBlock__wrap} dflex`}>
        {groups?.length > 0 ? (
          groups?.map((item) => (
            <div className={`${style.groupBlock__item} dflex`} key={item?.id}>
              <GroupCard group={item} handleTabsChange={handleTabsChange} handleShowModal={handleShowModal} buttonDisabled={buttonDisabled} />
            </div>
          ))
        ) : (
          <div className="dflex alignCenter justifyCenter w-full">
            <NoDataPlaceHolder message="No Group found in this list" />
          </div>
        )}
      </div>
    </div>
  );
};

export default GroupList;
