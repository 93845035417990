import React from 'react';
import Tooltip from '@mui/material/Tooltip';
export interface TooltipBlockProps {
  children: JSX.Element;
  holderContent?: any;
  className?: string;
  placement?: 'bottom-end' | 'bottom-start' | 'bottom' | 'left-end' | 'left-start' | 'left' | 'right-end' | 'right-start' | 'right' | 'top-end' | 'top-start' | 'top';
}
const TooltipBlock = ({ children, holderContent, className, placement }: TooltipBlockProps) => {
  return (
    <Tooltip
      //   leaveDelay={2000000}
      placement={placement}
      enterTouchDelay={0}
      arrow
      classes={{ popper: `${className} ${holderContent ? '' : 'isHidden'} tooltip-skin` }}
      title={<>{holderContent}</>}
    >
      {children && <span>{children}</span>}
    </Tooltip>
  );
};
export default TooltipBlock;
