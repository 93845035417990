import React, { useContext, useEffect, useState } from 'react';
import styles from './Register.module.scss';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import CustomButton from 'component/CustomButton';
import Input from 'component/input/Input';
import { useForm } from 'react-hook-form';
import OrganizationService from 'services/organizationService';
import LoadingScreen from 'component/LoadingScreen';
import { AuthContext } from 'context/AuthContext';
import { AuthContextInterface } from 'interface/interface';
import { TENANT_ID } from 'constants/constants';

const Register = () => {
  const { orgName, setOrgName } = useContext(AuthContext) as AuthContextInterface;
  const [loading, setLoading] = useState<boolean>(true);
  const { register, reset } = useForm({
    defaultValues: {
      orgName: orgName,
    },
  });
  const getTenant = async () => {
    setLoading(true);
    try {
      const orgService = new OrganizationService();
      const res = await orgService.getTenant(TENANT_ID);
      setOrgName(res?.data?.tenant?.orgName);
      reset({
        orgName: res?.data?.tenant?.orgName,
      });
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };
  useEffect(() => {
    getTenant();
  }, []);

  return loading ? (
    <div className={` ${styles.loader}`}>
      {' '}
      <LoadingScreen />
    </div>
  ) : (
    <div className={` ${styles.register}`}>
      <h1>Details</h1>
      <h3>Organization name</h3>
      <p>Human-friendly label for your organization, shown in user interfaces</p>
      <Input type="text" placeholder="Organization name" inputName="orgName" register={register} />

      <h3>Organization ID</h3>
      <p>Identifier for this organization sometimes used in API requests</p>
      <Input type="text" placeholder="org-1aMkAhsXX70EH34fyjePPE6C" inputName="brandName" register={register} />

      <h2>Integrations</h2>
      <h3>Weights and Biases</h3>
      <p>
        {' '}
        Your organization&apos;s Weights and Biases API Key. If set, enables the Weights and Biases integration for the{' '}
        <a target="_blank" href="/docs/guides/fine-tuning">
          fine-tuning API.
        </a>{' '}
        This key will be used to generate runs in your specified W&amp;B project. See the{' '}
        <a target="_blank" href="/docs/guides/fine-tuning/weights-and-biases-integration">
          documentation
        </a>{' '}
        for more information.
      </p>
      <div className={`${styles.register__upload}`}>
        <Input type="text" placeholder="" inputName="brandName" register={register} />
        <CustomButton buttonText="Update‍" className={'button'} />
      </div>

      <h2>Features and capabilities</h2>
      <h3>Threads</h3>
      <p>Threads page shows messages created with the Assistants API and Playground.</p>

      <FormControl>
        <RadioGroup aria-labelledby="demo-radio-buttons-group-label" defaultValue="Hidden" name="radio-buttons-group">
          <FormControlLabel value="1" control={<Radio />} label="Hidden" />
          <FormControlLabel value="2" control={<Radio />} label="Visible to organization owners" />
          <FormControlLabel value="3" control={<Radio />} label="Visible to everyone" />
        </RadioGroup>
      </FormControl>
      <h3>Usage dashboard visibility</h3>
      <p>Usage dashboard shows activity and costs for your organization.</p>
      <FormControl>
        <RadioGroup aria-labelledby="demo-radio-buttons-group-label" defaultValue="Hidden" name="radio-buttons-group">
          <FormControlLabel value="2" control={<Radio />} label="Visible to organization owners" />
          <FormControlLabel value="3" control={<Radio />} label="Visible to everyone" />
        </RadioGroup>
      </FormControl>
      <div className={`${styles.register__button}`}>
        <CustomButton buttonText="Save‍" className={'button'} />
      </div>
    </div>
  );
};

export default Register;
