import React, { useState } from 'react';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import styles from './CustomCarousel.module.scss';
import { testimonials } from 'constants/constants';
import { AnimatePresence, motion } from 'framer-motion';
// import arrowLeft from '../../assets/images/arrow-left-long.svg';
// import arrowRight from '../../assets/images/arrow-right-long.svg';

const CustomCarousel = () => {
  const [activeIndex, setActiveIndex] = useState<number>(0);
  const handleNext = (e: any) => {
    setActiveIndex(e);
  };
  const settings = {
    dots: false,
    infinite: true,
    speed: 20,
    autoplaySpeed: 10000,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    fade: true,
    afterChange: handleNext,
    touchThreshold: 0,
  };

  return (
    <div className={` ${styles.slider}`}>
      <div>
        <Slider {...settings}>
          {testimonials.map((testimonial: Record<string, string>) => (
            <div className={` ${styles.slider__main}`} key={testimonial.quote}>
              <AnimatePresence mode="wait">
                <motion.h2 className={styles.testimonial} key={testimonials[activeIndex].quote} initial={{ opacity: 0 }} animate={{ opacity: 1 }} exit={{ opacity: 0 }}>
                  {testimonials[activeIndex].quote.split('').map((char, index) => {
                    return (
                      <motion.span
                        key={char + index + activeIndex}
                        data-index={index}
                        aria-hidden="true"
                        initial={{ opacity: 0.2 }}
                        animate={{ opacity: 1 }}
                        transition={{ duration: 0.5, delay: index * 0.02 }}
                      >
                        {char}
                      </motion.span>
                    );
                  })}
                </motion.h2>
              </AnimatePresence>
              <div className={` ${styles.slider__mainFooter}`}>
                <AnimatePresence mode="wait">
                  <motion.p
                    className={styles.author}
                    initial={{ opacity: 0 }}
                    animate={{ opacity: 1 }}
                    exit={{ opacity: 0 }}
                    transition={{ duration: 0.5 }}
                    key={testimonials[activeIndex].author}
                  >
                    <span>{testimonials[activeIndex].author}</span>
                    <span className={styles.occupation}> {testimonials[activeIndex].occupation}</span>
                  </motion.p>
                </AnimatePresence>
              </div>
            </div>
          ))}
        </Slider>
      </div>
    </div>
  );
};

export default CustomCarousel;
