import React, { useContext } from 'react';
import styles from './FileUploadType.module.scss';
// import CustomButton from 'component/CustomButton';
//import { FormControlLabel, Radio } from '@mui/material';
// import FileUpload from 'component/fileUpload/FileUpload';
import FileUploaded from 'component/fileUploaded/FileUploaded';
// import SuccessPopper from 'component/successPopper/SuccessPopper';
// import sheet from '../../assets/images/google-sheets.png';
import { MeasurementContext } from 'context/MeasurementContext';
import { MeasurementChildContext } from 'context/MeasurementChildContext';
import { MeasurementContextInterface } from 'interface/interface';
import NoDataPlaceHolder from 'component/noDataPlaceholder/NoPlaceHolder';

// interface FileUploadTypeProps {
//   setShowRequirements: (a: boolean) => void;
//   showPopper: boolean;
//   handleShowModal: () => void;
//   showRequirements: boolean;
//   handleFileUploaded: (file: File[]) => void;
//   files: FileUploadData[];
//   fileFilter: (id: string) => void;
// }

const FileUploadType = () => {
  const { files } = useContext(MeasurementContext) as MeasurementContextInterface;
  const { fileFilter, handleDeleteFile } = useContext(MeasurementChildContext) as any;
  return (
    <>
      <div className={` ${styles.fileUploadWrapper}`}>
        <div className={` ${styles.fileUploadWrapperMain}`}>
          <div className={` ${styles.fileUpload}`}>
            {/* {showRequirements ? ( */}
            <>
              {/* <header className="dflex alignStart justifySpaceBetween">
                <div> */}
              {/* <h2>Onboard with just two inputs </h2> */}
              {/* <p>Start with your tech packs </p> */}
              {/* </div>
                <CustomButton buttonText="Add files" className={'button'} handleFunc={handleShowModal} />
              </header> */}

              {/* <div className={` ${styles.radioBtnList}`}>
                  <ul className="dflex">
                    <li>
                      <FormControlLabel control={<Radio checked />} label="Garment measurements" />
                      <p>For your entire size range </p>
                    </li>
                    <li>
                      <FormControlLabel value="female" control={<Radio checked />} label="Fit model measurements & base size" />
                      <p>Representing your ideal customer’s measurements and the size they wear. Crucial to our understanding of how your clothes are intended to be worn. </p>
                    </li>
                  </ul>
                </div> */}
              {/* <div className={` ${styles.downloadBox} dflex`}>
                <div className={` ${styles.downloadBox__box}`}>
                  <h3>Tech packs or Measurement Specs</h3>
                  <p>For your entire size range</p>
                  <div className={`flex alignCenter justifySpaceBetween ${styles.excelBox}`}>
                    <div className="flex alignCenter ">
                      <figure>
                        <img src={sheet} alt="icon" />
                      </figure>

                      <figcaption>
                        <h5>Google Sheets</h5>
                        <p>Example</p>
                      </figcaption>
                    </div>
                    <CustomButton buttonText="Download" className={'outline button gray'} />
                  </div>
                </div>
                <div className={` ${styles.downloadBox__box}`}>
                  <h3>Fit model measurements</h3>
                  <p>The ideal measurements for a customer wearing your middle size</p>
                  <div className={`flex alignCenter justifySpaceBetween ${styles.excelBox}`}>
                    <div className="flex alignCenter ">
                      <figure>
                        <img src={sheet} alt="icon" />
                      </figure>

                      <figcaption>
                        <h5>Google Sheets</h5>
                        <p>Example</p>
                      </figcaption>
                    </div>
                    <CustomButton buttonText="Download" className={'outline button gray'} />
                  </div>
                </div>
              </div> */}
            </>
            {/* ) : ( */}
            {/* <header className="dflex alignStart justifySpaceBetween">
                <>
                  <div>
                    <h2>Two input measurement upload </h2>
                    <p className={`${styles.blueText}`} onClick={() => setShowRequirements(true)}>
                      Show requirements
                    </p>
                  </div>
                  <div className={`${styles.btnRight} flex`}> */}
            {/* <CustomButton buttonText="Add note" className={'button outline'} /> */}
            {/* <CustomButton buttonText="Add files" className={'button'} handleFunc={handleShowModal} />
                  </div>
                </>
              </header>
            )} */}
            {files?.length > 0 ? (
              <div className={`${styles.fileUploadedSec}`}>
                <div className={`${styles.upFileList} `}>
                  <FileUploaded files={files} fileFilter={fileFilter} handleDeleteFile={handleDeleteFile} />
                </div>
              </div>
            ) : (
              // <div className={`${styles.fileUploadSec}`}>
              //   <FileUpload handleFileUploaded={handleFileUploaded} />
              // </div>
              <div className="dflex alignCenter justifyCenter w-full">
                <NoDataPlaceHolder message="No files found in this list" />
              </div>
            )}
          </div>
        </div>

        {/* <SuccessPopper show={showPopper} /> */}
      </div>
    </>
  );
};

export default FileUploadType;
