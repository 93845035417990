export enum ToastTypes {
  SUCCESS = 'SUCCESS',
  ERROR = 'ERROR',
  INFO = 'INFO',
}

export enum TOKEN_DATA_TYPE {
  ACCESS = 'ACCESS',
  INTERNAL = 'INTERNAL',
}

export enum AUTH_TYPE {
  LOGIN = 'LOGIN',
  LOGOUT = 'LOGOUT',
}
export enum AUTH_TYPE_URL {
  DASHBOARD_LOGIN = 'dashboard_login',
  DASHBOARD_SIGNUP = 'dashboard_signup',
}

export enum SHOW_CASE {
  AUTH = 'AUTH',
  LOAD_PRODUCT = 'LOAD_PRODUCT',
  ALL_PRODUCTS = 'ALL_PRODUCTS',
  FILE_UPLOAD_TYPE = 'FILE_UPLOAD_TYPE',
  NONE = 'NONE',
  REVIEW_TABLE = 'REVIEW_TABLE',
}

export enum SHOW_FILE_TYPE {
  INDIVIDUAL = 'INDIVIDUAL',
  GROUP = 'GROUP',
}

export enum FETCH_LIST_TYPE {
  MEASUREMENT_SUCCESS_FIT_VIEW = '/measurement_success/fit-view',
  MEASUREMENT_SUCCESS_SHOPIFY = '/measurement_success/shopify',
  PRODUCT_REQUEST = '/product_request',
  SCAN_SUCCESS = '/scan_success',
  USER_LIST = '',
  SIZING_SUGGESTIONS = 'Order Processed',
  SWAN_POWERED_SALES = 'Scan Outcome Requested',
}
export enum DATE_PICKER {
  TODAY = 'today',
  LAST_24HRS = 'last24hrs',
  LAST_7DAYS = 'last7days',
  LAST_14DAYS = 'last14days',
  LAST_30DAYS = 'last30days',
  THIS_MONTH = 'this_month',
  CUSTOM_DATE = 'custom_date',
}

export enum DATE_PICKER_VALUE {
  TODAY = 'dStart',
  TWENTY_FOUR_HOURS = '-24hr',
  SEVEN_DAYS = '-7d',
  FOURTEEN_DAYS = '-14d',
  THIRTY_DAYS = '-30d',
  THIS_MONTH = 'mStart',
  CUSTOM = 'custom',
}

// export enum EVENTS {
//   TOTAL_SCANS = 'User Created',
//   SWAN_POWERED_SALES = 'Scan Outcome Requested',
// }

export enum URL_TYPE {
  NEXT = 'next',
  PREVIOUS = 'prev',
}
export enum PRODUCT_TYPE {
  PRODUCTS = 'PRODUCTS',
  COLLECTIONS = 'COLLECTIONS',
  GROUPS = 'GROUPS',
}

export enum TechPackChildren {
  SIZES = 'sizes',
  EASE = 'ease',
}

export enum AlterType {
  EDIT = 'EDIT',
  DELETE = 'DELETE',
  ONLY_EDIT = 'ONLY_EDIT',
}
export enum CheckStatusType {
  GET_PROMISE_ARRAY = 'get_promise_array',
  GET_FILES_ARRAY = 'get_files_array',
}

export enum ModalContentType {
  EDIT = 'EDIT',
  CREATE = 'CREATE',
  CHANGE = 'CHANGE',
  OVERRIDE = 'OVERRIDE',
  ONBOARD = 'ONBOARD',
  DELETE = 'DELETE',
  NONE = '',
}
export enum TechPackKeys {
  CATEGORY = 'category',
  DESCRIPTION = 'description',
  BASE_SIZE = 'base_size',
  DIVISION = 'division',
}

export enum TabsValue {
  ONE = '1',
  TWO = '2',
  THREE = '3',
  FOUR = '4',
}

export enum SortType {
  ASCENDING = 'ASCENDING',
  DESCENDING = 'DESCENDING',
}
export enum ScanType {
  CLOTHING_SCAN = 'clothing_scan',
  CLOTHING_CUSTOM_SCAN = 'clothing_custom_scan',
  CLOTHING_CUSTOM_CALIBRATION = 'clothing_custom_calibration',
}
export enum Metrics {
  SCAN_COMPLETE_RATE = 'Scan completion rate ',
  SCAN_ACCURACY = 'Scan Accuracy',
  CAMERA_ACTIVATION_RATE = 'Camera Activation Rate',
  COMPLETION_AND_CONFIRMATION_RATE = 'Completion and Confirmation Rate',
  TOTAL_SCANS = 'Total Scans',
  SIZING_SUGGESTIONS = 'Sizing Suggestions',
  SWAN_POWERED_SALES = 'Swan Powered Sales',
  EMAIL_VERIFICATION_RATE = 'Email Verification Rate',
}
