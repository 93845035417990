import React from 'react';
import { createBrowserRouter, Navigate, Outlet, RouterProvider } from 'react-router-dom';
import Login from 'pages/login/Login';
import Users from 'pages/users/Users';
import DesignWrapper from 'wrapper/DesignWrapper';
import App from 'App';
import Supports from 'pages/supports/Supports';
import Settings from 'pages/settings/Settings';
import { ROUTES } from 'constants/constants';
import Measurements from 'pages/measurements/Measurements';
import LoggedOutWrapper from 'wrapper/LoggedOutWrapper';
import LoggedInWrapper from 'wrapper/LoggedInWrapper';
import UserVerify from 'pages/userVerify/UserVerify';
import List from 'pages/analytic/List';
import Referrals from 'pages/referrals/Referrals';
import CustomerList from 'pages/customerList/CustomerList';
import Resources from 'pages/resources/Resources';
import Custom from 'pages/custom/Custom';
import Analytics from 'pages/kpiAnalytic/Analytics';
import MeasurementContextProvider from 'context/MeasurementContext';
import Home from 'pages/home/Home';
import Register from 'pages/register/Register';
import ApiKeys from 'pages/apiKeys/ApiKeys';

function ProjectRoutes() {
  const router = createBrowserRouter([
    {
      path: ROUTES.HOME,
      element: <App />,
      children: [
        {
          path: ROUTES.HOME,
          element: (
            <LoggedInWrapper>
              <DesignWrapper>
                <Home />
              </DesignWrapper>
            </LoggedInWrapper>
          ),
        },
        {
          path: ROUTES.LOGIN,
          element: (
            <LoggedOutWrapper>
              <Login />
            </LoggedOutWrapper>
          ),
        },
        {
          path: ROUTES.NOT_MATCH,
          element: (
            <LoggedInWrapper>
              <Navigate to={ROUTES.HOME} replace />,
            </LoggedInWrapper>
          ),
        },

        {
          path: ROUTES.MEASUREMENTS,
          element: (
            <MeasurementContextProvider>
              <LoggedInWrapper>
                <DesignWrapper>
                  <Measurements />
                </DesignWrapper>
              </LoggedInWrapper>
            </MeasurementContextProvider>
          ),
        },
        {
          path: ROUTES.CUSTOM,
          element: (
            <LoggedInWrapper>
              <DesignWrapper>
                <Custom />
              </DesignWrapper>
            </LoggedInWrapper>
          ),
          children: [
            {
              path: ROUTES.CUSTOM_DETAILS,
              element: (
                <LoggedInWrapper>
                  <DesignWrapper>
                    <Custom />
                  </DesignWrapper>
                </LoggedInWrapper>
              ),
            },
          ],
        },
        {
          path: ROUTES.ORGANIZATION,
          element: (
            <LoggedInWrapper>
              <DesignWrapper>
                <Outlet />
              </DesignWrapper>
            </LoggedInWrapper>
          ),
          children: [
            {
              path: ROUTES.ORGANIZATION_REGISTER,
              element: (
                <LoggedInWrapper>
                  <Register />
                  {/* <DesignWrapper>
                    <Register />
                  </DesignWrapper> */}
                </LoggedInWrapper>
              ),
            },
            {
              path: ROUTES.ORGANIZATION_API_KEYS,
              element: (
                <LoggedInWrapper>
                  {/* <DesignWrapper> */}
                  <ApiKeys />
                  {/* </DesignWrapper> */}
                </LoggedInWrapper>
              ),
            },
          ],
        },
        {
          path: ROUTES.USERS,
          element: (
            <LoggedInWrapper>
              <DesignWrapper>
                <Users />
              </DesignWrapper>
            </LoggedInWrapper>
          ),
        },
        {
          path: ROUTES.RESOURCES,
          element: (
            <LoggedInWrapper>
              <DesignWrapper>
                <Resources />
              </DesignWrapper>
            </LoggedInWrapper>
          ),
        },
        {
          path: ROUTES.SUPPORT,
          element: (
            <LoggedInWrapper>
              <DesignWrapper>
                <Supports />
              </DesignWrapper>
            </LoggedInWrapper>
          ),
        },
        {
          path: ROUTES.SETTINGS,
          element: (
            <LoggedInWrapper>
              <DesignWrapper>
                <Settings />
              </DesignWrapper>
            </LoggedInWrapper>
          ),
        },
        {
          path: ROUTES.ANALYTICS,
          element: (
            <LoggedInWrapper>
              <DesignWrapper>
                <List />
              </DesignWrapper>
            </LoggedInWrapper>
          ),
        },
        {
          path: ROUTES.KPI_ANALYTICS,
          element: (
            <LoggedInWrapper>
              <DesignWrapper>
                <Analytics />
              </DesignWrapper>
            </LoggedInWrapper>
          ),
        },
        {
          path: ROUTES.REFERRALS,
          element: (
            <LoggedInWrapper>
              <DesignWrapper>
                <Referrals />
              </DesignWrapper>
            </LoggedInWrapper>
          ),
        },
        { path: ROUTES.CUSTOMERS_LIST, element: <CustomerList /> },
        {
          path: ROUTES.USER_VERIFY,
          element: <UserVerify />,
        },
      ],
    },
  ]);

  return <RouterProvider router={router} />;
}

export default ProjectRoutes;
