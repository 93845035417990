import { API_END_POINTS } from 'constants/constants';
import UseApiService from './apiService';
import { ApiDataObject, UserFormData } from 'interface/interface';
import { TOKEN_DATA_TYPE } from 'enum/enum';

export const authenticateUser = (email: string, appUrl: string) => {
  const body = {
    url: API_END_POINTS.AUTHORIZE_USER,
    data: {
      email,
      appUrl: `${window.location.origin}${appUrl}`,
    },
  };
  return UseApiService().post(body);
};

export const registerUser = (data: UserFormData) => {
  const body = {
    url: API_END_POINTS.CUSTOMER,
    data,
  };
  return UseApiService().post(body);
};
export const verifyUser = (token: string) => {
  const body = {
    url: API_END_POINTS.AUTH_CALLBACK,
    data: {
      token,
    },
  };
  return UseApiService().get(body);
};

export const getCustomersList = () => {
  const body = {
    url: API_END_POINTS.CUSTOMER,
    headerToken: TOKEN_DATA_TYPE.INTERNAL,
  };
  return UseApiService().get(body);
};

export const getToken = (store_url: string) => {
  const body = {
    url: API_END_POINTS.INTERNAL_AUTH,
    data: {
      store_url,
    },
    headerToken: TOKEN_DATA_TYPE.INTERNAL,
  };
  return UseApiService().post(body);
};

export const customerOnboarding = (store_url: string, onboarded: boolean) => {
  const body = {
    url: API_END_POINTS.CUSTOMER + API_END_POINTS.ONBOARD,
    data: { store_url, onboarded },
    headerToken: TOKEN_DATA_TYPE.INTERNAL,
  };
  return UseApiService().post(body);
};
export const customScan = (pageNum = 1, pageSize = 10) => {
  const body: ApiDataObject = {
    url: `${API_END_POINTS.CUSTOM_FIT}`,
    headerToken: TOKEN_DATA_TYPE.ACCESS,
    data: {
      pageNum,
      pageSize,
    },
  };

  return UseApiService().get(body);
};
