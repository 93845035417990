/* eslint-disable max-lines */
import { AuthContext } from 'context/AuthContext';
import { DATE_PICKER_VALUE, FETCH_LIST_TYPE } from 'enum/enum';
import { AuthContextInterface } from 'interface/interface';
import React, { useContext, useEffect, useState } from 'react';
import { fetchList, getProductsSupported } from 'services/listService';
import styles from './List.module.scss';
import DateFilter from 'component/dateFilter/DateFilter';
import CustomDialogModal from 'component/CustomDialogModal';
// import { ListChild } from './ListChild';
import { getDate } from 'utils/utils';
import { ListChildNew } from './ListChildNew';
import PageTitle from 'component/pageTitle/PageTitle';
import { FULFILLED, REACT_APP_POSTHOG_LIVE_PROJECT_ID } from 'constants/constants';
import { getFilteredSizingSuggestionListLength, removeUnwantedEmails } from 'utils/utils';
import LoadingScreen from 'component/LoadingScreen';

const List = () => {
  const [datePickerValue, setDatePickerValue] = useState<DATE_PICKER_VALUE>(DATE_PICKER_VALUE.TODAY);
  const [scanUsersList, setScanUsersList] = useState<any[]>([]);
  const [loading, setLoading] = useState<boolean>(true);
  const [noOfSizingSuggestions, setNoOfSizingSuggestions] = useState<number>(0);
  // const [swanPoweredSalesData, setSwanPoweredSalesData] = useState<any[]>([]);
  const [productDetails, setProductDetails] = useState<any>({});
  const [startDate, setStartDate] = useState<Date>(new Date());
  const [endDate, setEndDate] = useState(new Date());
  const [open, setOpen] = useState(false);
  const { userDetails } = useContext(AuthContext) as AuthContextInterface;

  const handleShowModal = () => {
    setOpen(!open);
  };

  const fetchAllList = async (custom = false) => {
    setLoading(true);
    try {
      let after: DATE_PICKER_VALUE | string = datePickerValue;
      let before = '';
      if (custom) {
        after = getDate(startDate);
        before = endDate ? getDate(endDate) : '';
      }
      const fetchPromisesCombined = [
        fetchList({ projectId: REACT_APP_POSTHOG_LIVE_PROJECT_ID ?? '', after, event: userDetails?.store_url, before }),
        getProductsSupported(),
        fetchList({ projectId: REACT_APP_POSTHOG_LIVE_PROJECT_ID ?? '', after, event: FETCH_LIST_TYPE.SIZING_SUGGESTIONS, before }),
      ];

      let userListLive: any[] = [],
        sizingSuggestionLive = 0,
        swanPSLive: any[] = [];
      const resolvedResults = await Promise.allSettled(fetchPromisesCombined);

      resolvedResults?.forEach((result, index) => {
        if (result?.status === FULFILLED) {
          const data: any = result?.value;
          switch (index) {
            case 0: {
              userListLive = data;
              break;
            }
            case 1: {
              swanPSLive = data?.data;
              break;
            }
            default: {
              sizingSuggestionLive = getFilteredSizingSuggestionListLength(data, userDetails?.store_url);
            }
          }
        }
      });

      setScanUsersList(removeUnwantedEmails(userListLive));
      setNoOfSizingSuggestions(sizingSuggestionLive);
      setProductDetails(swanPSLive);
    } catch (err) {
      console.log('error=', err);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (datePickerValue !== DATE_PICKER_VALUE.CUSTOM) {
      fetchAllList();
    }
  }, [datePickerValue]);
  console.log(startDate, 'start');
  console.log(endDate, 'end');

  return (
    <div className={`${styles.listMain}`}>
      <CustomDialogModal
        open={open}
        handleShowModal={handleShowModal}
        startDate={startDate}
        setStartDate={setStartDate}
        endDate={endDate}
        setEndDate={setEndDate}
        setDatePickerValue={setDatePickerValue}
        fetchAllList={fetchAllList}
      />

      <PageTitle name={userDetails?.name} />
      <div className={`${styles.main}`}>
        <div className={`${styles.dateSelect}`}>
          <div className={`${styles.datePicker}`}>
            <DateFilter
              setDatePickerValue={setDatePickerValue}
              datePickerValue={datePickerValue}
              handleShowModal={handleShowModal}
              startDate={getDate(startDate)}
              endDate={getDate(endDate)}
            />
          </div>
        </div>

        {loading ? (
          <LoadingScreen />
        ) : (
          <ListChildNew usersList={scanUsersList} datePickerValue={datePickerValue} noOfSizingSuggestions={noOfSizingSuggestions} productDetails={productDetails} />
        )}
      </div>
    </div>
  );
};

export default List;
