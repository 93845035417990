/* eslint-disable no-debugger */
import axios, { AxiosResponse } from 'axios';
import { API_END_POINTS, REACT_APP_POSTHOG_PERSONAL_API_KEY, REACT_APP_POSTHOG_URL } from 'constants/constants';
import { DATE_PICKER_VALUE, TOKEN_DATA_TYPE } from 'enum/enum';
import UseApiService from './apiService';

const header = {
  Authorization: `Bearer ${REACT_APP_POSTHOG_PERSONAL_API_KEY}`,
};

async function fetchData(url: string, dataList: any[]): Promise<any[]> {
  try {
    const response: AxiosResponse = await axios.get(url, { headers: header });
    const data = response?.data;
    dataList.push(...data.results);
    if (data?.next) {
      return await fetchData(data.next, dataList);
    } else {
      return dataList;
    }
  } catch (error: any) {
    throw new Error(error?.message ?? 'something went wrong');
  }
}

// desc = orderby= -last_modified_at and asc = orderby=last_modified_at
interface fetchListProps {
  projectId: string;
  after: DATE_PICKER_VALUE | string;
  event: string;
  before?: string;
  extraParams?: string;
}
export const fetchList = async ({ projectId, after, event, before = '', extraParams = '' }: fetchListProps) => {
  let url = `${REACT_APP_POSTHOG_URL}${API_END_POINTS.POSTHOG_PROJECT}/${projectId}/events?event=${event}&after=${after}&before=${before}`;

  if (event === 'sarah-alexandra-online.myshopify.com') {
    url += `&properties={"gender":"female"}`;
  } else if (extraParams) {
    url += `&properties={${extraParams}}`;
  }
  const dataList: any[] = [];
  return fetchData(url, dataList);
};

export const getProductsSupported = async () => {
  const body = {
    url: API_END_POINTS.DASHBOARD_METRICS,
    headerToken: TOKEN_DATA_TYPE.ACCESS,
  };

  return UseApiService().get(body);
};
