import * as React from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableRow from '@mui/material/TableRow';
import Checkbox from '@mui/material/Checkbox';
import EnhancedTableHead from './EnhancedTableHead';
import { useState } from 'react';
import { HeadCells } from 'constants/constants';
import NoDataPlaceHolder from 'component/noDataPlaceholder/NoPlaceHolder';
import CheckIcon from 'assets/images/check.svg';
import style from './TableCompoWithCheckBox.module.scss';
import { Switch } from '@mui/material';
import { ModalContentType } from 'enum/enum';
import { deepCopy } from 'utils/utils';
import LoadingScreen from 'component/LoadingScreen';

interface TableCompoWithStartingCheckBoxProps {
  data: any[];
  handleProductOnBoard: (a: boolean, id: string) => void;
  allToggle?: boolean;
  handleToggleAll?: (a: boolean) => void;
  selectedProduct: string[];
  setSelectedProduct: (a: string[]) => void;
  handleShowModal: (a?: ModalContentType, id?: string) => void;
  loading: boolean;
  showCheckBox?: boolean;
  showToggle: boolean;
  buttonDisabled?: boolean;
  // title?: string;
}

const TableCompoWithStartingCheckBox = ({
  data,
  handleProductOnBoard,
  allToggle,
  handleToggleAll,
  handleShowModal,
  loading,
  showCheckBox = true,
  showToggle = true,
  buttonDisabled = false,
  selectedProduct,
  setSelectedProduct,
}: TableCompoWithStartingCheckBoxProps) => {
  const [headCells] = useState<{ label: string; id: number }[]>(deepCopy(HeadCells));
  // const [selectedProduct, setSelectedProduct] = useState<string[]>([]);
  const handleSelectAllClick = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.checked) {
      const newSelected = data.map((n) => n.shopifyProductId);
      setSelectedProduct(newSelected);
      return;
    }
    setSelectedProduct([]);
  };

  const handleClick = (id: string) => {
    const selectedIndex = selectedProduct.indexOf(id);
    let newSelected: string[] = [];
    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selectedProduct, id);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selectedProduct.slice(1));
    } else if (selectedIndex === selectedProduct.length - 1) {
      newSelected = newSelected.concat(selectedProduct.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(selectedProduct.slice(0, selectedIndex), selectedProduct.slice(selectedIndex + 1));
    }

    setSelectedProduct(newSelected);
  };
  const isSelected = (name: string) => selectedProduct.indexOf(name) !== -1;

  // Avoid a layout jump when reaching the last page with empty rows.
  // React.useEffect(() => {
  //   if (title) {
  //     headCells[0].label = title;
  //     setHeadCells([...headCells]);
  //   }
  // }, [title]);
  return (
    <>
      <TableContainer className={style.tableBlock}>
        <Table aria-labelledby="tableTitle">
          <EnhancedTableHead
            numSelected={selectedProduct?.length}
            onSelectAllClick={handleSelectAllClick}
            rowCount={data?.length}
            headCells={headCells}
            allToggle={allToggle}
            handleToggleAll={handleToggleAll}
            showCheckBox={showCheckBox}
            showToggle={showToggle}
            buttonDisabled={buttonDisabled}
          />
          {data?.length > 0 ? (
            <TableBody>
              {data?.map((row, index) => {
                const isItemSelected = isSelected(row?.shopifyProductId);
                const labelId = `enhanced-table-checkbox-${row?.shopifyProductId}`;
                return (
                  <TableRow hover role="checkbox" tabIndex={-1} key={row?.shopifyProductId + index}>
                    <TableCell component="th" id={row?.shopifyProductId} scope="row" padding="none">
                      <div className="flex alignCenter">
                        {showCheckBox && (
                          <Checkbox
                            onChange={() => handleClick(row?.shopifyProductId)}
                            color="primary"
                            checked={isItemSelected}
                            inputProps={{
                              'aria-labelledby': labelId,
                            }}
                          />
                        )}
                        <h5 className={style.tableBlock__name}>
                          <a className={style.tableBlock__view} href={row?.onlineStoreUrl} target="_blank" rel="noreferrer">
                            {row?.title}{' '}
                          </a>
                        </h5>
                      </div>
                    </TableCell>
                    <TableCell>
                      <div className={style.tableBlock__key}>
                        <p>
                          {row?.techpackName && <strong>{row?.techpackName}</strong>}
                          <span className={style.tableBlock__tag} onClick={() => handleShowModal(ModalContentType.CHANGE, row?.shopifyProductId)}>
                            {row?.techpackName ? ' Edit' : `Add`}
                          </span>
                        </p>
                      </div>
                    </TableCell>
                    {/* <TableCell>
                      <a className={style.tableBlock__view} href={row?.onlineStoreUrl} target="_blank" rel="noreferrer">
                        {row?.onlineStoreUrl}
                      </a>c
                    </TableCell> */}

                    {/* <TableCell>
                      <div className={`${style.tableBlock__status} ${row?.isOnboarded && style.isActive}`}>
                        {row?.isOnboarded ? (
                          <>
                            <img src={CheckIcon} alt="Check" />
                            <span>Live</span>
                          </>
                        ) : (
                          'To be onboarded'
                        )}
                      </div>
                    </TableCell> */}

                    <TableCell>
                      <div className={`${style.tableBlock__status} ${row?.isOnboarded ? style.isActive : ''}`}>
                        {row?.isOnboarded ? (
                          <>
                            <img src={CheckIcon} alt="Check" />
                            <span>Live</span>
                          </>
                        ) : (
                          'To be onboarded'
                        )}
                      </div>
                      {showToggle && (
                        <Switch
                          checked={row?.isOnboarded}
                          disabled={!row?.sizes?.length || buttonDisabled}
                          onChange={(e) => handleProductOnBoard(e.target.checked, row?.shopifyProductId)}
                        />
                      )}
                    </TableCell>
                  </TableRow>
                );
              })}

              {loading && data?.length > 0 && (
                <tr>
                  <td colSpan={6}>
                    <div className={`${style.tableBlock__loader} dflex alignCenter justifyCenter w-full`}>
                      <LoadingScreen height={false} />
                    </div>
                  </td>
                </tr>
              )}
            </TableBody>
          ) : (
            <tbody>
              <tr>
                <td colSpan={20}>
                  <div className={`${style.tableBlock__noData} dflex alignCenter justifyCenter w-full`}>
                    <NoDataPlaceHolder message="No Product found in this list" />
                  </div>
                </td>
              </tr>
            </tbody>
          )}
        </Table>
      </TableContainer>
    </>
  );
};

export default TableCompoWithStartingCheckBox;
