import React from 'react';
import CustomModal from '../CustomModal';
import CustomButton from '../CustomButton';
import styles from './ConfirmKeyDisableModal.module.scss';

interface ConfirmKeyDisableModalProps {
  showModal: boolean;
  handleShowModal: () => void;
  handleDisableApiKey: () => void;
}

const ConfirmKeyDisableModal = ({ showModal, handleShowModal, handleDisableApiKey }: ConfirmKeyDisableModalProps) => {
  return (
    <CustomModal showModal={showModal} handleShowModal={handleShowModal}>
      <div className={` ${styles.modal}`}>
        <h2>Are you sure want to disable?</h2>
        {/* <p>Click on confirm for disable</p> */}
        <div className={` ${styles.modal__button} dflex alignCenter justifyCenter`}>
          <CustomButton buttonText="Cancel" handleFunc={handleShowModal} className="button outline" />
          <CustomButton buttonText="Confirm" handleFunc={handleDisableApiKey} className="button" />
        </div>
      </div>
    </CustomModal>
  );
};

export default ConfirmKeyDisableModal;
