import CategoriesAndProducts from 'component/categoriesAndProducts/CategoriesAndProducts';
// import FileUploadType from 'component/fileUploadType/FileUploadType';
import ShowSuccessMessage from 'component/showMessage/ShowSuccessMessage';
import { SHOW_CASE } from 'enum/enum';
// import { ChildFunctionProps } from 'interface/interface';
import ReviewTable from 'component/review/ReviewTable';
import React, { useContext } from 'react';
import { MeasurementContext } from 'context/MeasurementContext';
import { MeasurementContextInterface } from 'interface/interface';

export const ChildFunction = () => {
  const { showCase } = useContext(MeasurementContext) as MeasurementContextInterface;

  switch (showCase) {
    case SHOW_CASE.ALL_PRODUCTS: {
      return <CategoriesAndProducts />;
    }
    // case SHOW_CASE.FILE_UPLOAD_TYPE: {
    //   return <FileUploadType />;
    // }
    case SHOW_CASE.REVIEW_TABLE: {
      return <ReviewTable />;
    }
    default: {
      return (
        <div className="dflex alignCenter justifyCenter successMeasurement">
          <ShowSuccessMessage fileUploadSuccess={true} />
        </div>
      );
    }
  }
};
