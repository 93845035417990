import React, { useContext, useEffect, useRef } from 'react';
import { ModalContentType, PRODUCT_TYPE, SHOW_CASE, TabsValue, ToastTypes, URL_TYPE } from 'enum/enum';
import { getAllProducts, getCollectionsProduct, getListCollections, getTechPackData, onboardProduct, submitTechPackData } from 'services/productServices';
import { ToastContext } from 'context/Toast';
import { AuthContextInterface, FileUploadData, GroupData, MeasurementContextInterface, ToastInterface } from 'interface/interface';
import {
  addSizesInTechpack,
  getCollectionsOnboardArray,
  getOnboardArray,
  handleErrorMessage,
  modifyCollection,
  modifyGroupsData,
  updateAll,
  updateProductStatus,
} from 'utils/utils';
import { PRODUCTS_DO_NOT_HAVE_SAME_SIZE, SOMETHING_WENT_WRONG } from 'constants/constants';
import MeasurementOnBoarding from 'component/measurementOnboarding/MeasurementOnBoarding';
import styles from './Measurements.module.scss';
import CustomButton from 'component/CustomButton';
import SubHeader from 'component/subHeader/SubHeader';
import { AuthContext } from 'context/AuthContext';
import { v4 as uuidv4 } from 'uuid';
import { uploadNotes, fileUploadUrlPromiseArray, deleteFile, getUploadedFiles } from 'services/fileUploadServices';
import CustomModal from 'component/CustomModal';
import CreateGroup from 'component/createGroup/CreateGroup';
import { ChildFunction } from './ChildFunction';
import {
  addProductsToGroup,
  createGroup,
  deleteGroup,
  fetchGroupSizes,
  getGroupProducts,
  getGroups,
  groupOnboard,
  modifyGroup,
  removeProductsFromGroup,
} from 'services/groupServices';
import { MeasurementContext } from 'context/MeasurementContext';
import { MeasurementChildContext } from 'context/MeasurementChildContext';

let timerId: NodeJS.Timeout;
let allToggleTimerId: NodeJS.Timeout;
let productsTimerId: NodeJS.Timeout;

const Measurements = () => {
  const {
    showCase,
    setShowCase,
    setLoading,
    setChildLoading,
    setCollectionLoading,
    allProducts,
    setAllProducts,
    setProductPageInfo,
    allCollections,
    setAllCollections,
    buttonDisabled,
    setButtonDisabled,
    progress,
    setProgress,
    techPackRes,
    setTechPackRes,
    showModal,
    setShowModal,
    files,
    setFiles,
    approvedNotes,
    setApprovedNotes,
    multiFiles,
    setMultiFiles,
    groups,
    setGroups,
    modalContentType,
    setModalContentType,
    value,
    setValue,
    setGroupDetail,
    selectedProduct,
    setSelectedProduct,
    setSizesMatch,
    setChildButtonDisabled,
    groupDetail,
    uploadFileModal,
    setUploadFileModal,
  } = useContext(MeasurementContext) as MeasurementContextInterface;
  const { callToast } = useContext(ToastContext) as ToastInterface;
  const { isInternalUser } = useContext(AuthContext) as AuthContextInterface;
  const onBoardedCollectionObj = useRef<Record<string, any>>({});
  const productAllToggle = useRef<boolean>(false);
  const storeId = useRef<string>('');
  const storeToggle = useRef<boolean>(false);
  const newGroupId = useRef<string>('');

  const handleNext = () => {
    productAllToggle.current = false;
    if (showCase === SHOW_CASE.ALL_PRODUCTS && progress === 1) {
      setProgress(2);
      setShowCase(SHOW_CASE.REVIEW_TABLE);
      fetchGroups();
    } else if (showCase === SHOW_CASE.REVIEW_TABLE) {
      setShowCase(SHOW_CASE.NONE);
    }
  };
  const handleBack = () => {
    switch (showCase) {
      case SHOW_CASE.ALL_PRODUCTS: {
        setShowCase(SHOW_CASE.LOAD_PRODUCT);
        break;
      }
      case SHOW_CASE.REVIEW_TABLE: {
        setShowCase(SHOW_CASE.ALL_PRODUCTS);
        setProgress(1);
      }
    }
  };
  const handleProgressBar = (progress: number) => {
    switch (progress) {
      case 1: {
        setProgress(1);
        setShowCase(SHOW_CASE.ALL_PRODUCTS);
        if (value === TabsValue.FOUR) {
          return;
        }
        if (value === TabsValue.TWO) {
          fetchCollections();
        } else {
          setAllProducts([]);
          fetchProducts();
        }
        break;
      }
      case 2: {
        setProgress(2);
        setShowCase(SHOW_CASE.REVIEW_TABLE);
        fetchGroups();
        break;
      }
      default: {
        setProgress(4);
        setShowCase(SHOW_CASE.NONE);
      }
    }
  };
  const handleShowModal = (contentType = ModalContentType.NONE, Id = '', flag = false) => {
    if (multiFiles?.length) {
      setMultiFiles([]);
      setApprovedNotes({ notes: '', approved: false, noteId: '' });
    }
    if (showCase === SHOW_CASE.ALL_PRODUCTS || showCase === SHOW_CASE.REVIEW_TABLE) {
      if (Id) {
        storeId.current = Id;
      }
      storeToggle.current = flag;
      if (contentType) {
        setModalContentType(contentType);
      }
    }
    setShowModal(!showModal);
  };
  const handleTabsChange = (event: React.SyntheticEvent, newValue: string, id = '') => {
    productAllToggle.current = false;
    setAllProducts([]);
    setSelectedProduct([]);
    setAllCollections({});
    setFiles([]);
    if (newValue === TabsValue.ONE) {
      fetchProducts();
    } else if (newValue === TabsValue.TWO) {
      fetchCollections();
    } else if (newValue === TabsValue.THREE) {
      fetchGroups();
    } else {
      const a = groups?.find((el: GroupData) => el?.id === id);
      if (a) {
        setGroupDetail(a);
      }
      fetchGroupProducts(id);
      getAllFilesByGroup(id);
    }
    setValue(newValue);
  };
  const handleFileUploaded = (files: File[]) => {
    const newModFile = files?.map((file: File) => {
      const id = uuidv4();
      return {
        file,
        id,
        uploaded: false,
        show: true,
        showProgress: true,
        showCross: true,
      };
    });
    setMultiFiles((prev: any) => [...prev, ...newModFile]);
  };
  const getAllFilesByGroup = async (id: string) => {
    try {
      const res = await getUploadedFiles(id);
      const modFileRes =
        res?.data?.files?.map((el: any) => {
          const id = uuidv4();
          return {
            file: { name: el?.file, size: el?.size },
            id,
            uploaded: true,
            show: true,
            showProgress: false,
            showUploaded: false,
            showDelete: true,
          };
        }) ?? [];
      if (!modFileRes?.length) {
        setFiles([]);
      } else {
        setFiles([...modFileRes]);
      }
    } catch (error) {
      callToast(ToastTypes.ERROR, handleErrorMessage(error));
    }
  };
  const fileFilter = async (id: string) => {
    const filteredFiles = multiFiles?.filter((el: any) => el.id !== id);
    setMultiFiles([...filteredFiles]);
  };
  const handleDeleteFile = async (id: string) => {
    let file: any;
    const filteredFiles = files?.filter((el: any) => {
      if (el?.id !== id) {
        return true;
      } else {
        file = el;
        return false;
      }
    });
    setFiles([...filteredFiles]);
    try {
      await deleteFile(file?.file?.name, groupDetail?.id);
    } catch (error) {
      callToast(ToastTypes.ERROR, handleErrorMessage(error));
    }
  };
  const uploadMultipleFiles = async () => {
    setLoading(true);
    const newMultiFiles = multiFiles.map((el: any) => {
      el.showProgress = true;
      return el;
    });
    setMultiFiles(newMultiFiles);
    try {
      let uploadMultiFileRes;
      if (approvedNotes.notes && uploadFileModal) {
        const res = await uploadNotes(approvedNotes);
        uploadMultiFileRes = await fileUploadUrlPromiseArray({ files: newMultiFiles, noteId: res?.data?.noteId, groupId: groupDetail?.id });
      } else {
        uploadMultiFileRes = await fileUploadUrlPromiseArray({ files: newMultiFiles, groupId: groupDetail?.id });
      }
      const filteredMultipleFile = uploadMultiFileRes?.filter((el: FileUploadData) => el?.uploaded);
      if (filteredMultipleFile?.length !== newMultiFiles?.length) {
        callToast(ToastTypes.ERROR, SOMETHING_WENT_WRONG);
      }
      setFiles([...files, ...filteredMultipleFile]);
      setApprovedNotes({ notes: '', approved: false, noteId: '' });
      setTimeout(() => {
        setMultiFiles(() => []);
        setUploadFileModal(!uploadFileModal);
        setButtonDisabled(false);
      }, 500);
    } catch (error: any) {
      callToast(ToastTypes.ERROR, handleErrorMessage(error));
    } finally {
      setLoading(false);
    }
  };

  const handleCreateGroup = async ({ groupName = '', overrideSizeMismatch = false }: { groupName?: string; overrideSizeMismatch?: boolean }) => {
    setButtonDisabled(true);

    try {
      if (!newGroupId.current) {
        const res = await createGroup(groupName);
        newGroupId.current = res?.data?.id;
      }
      if (selectedProduct?.length) {
        await addProductsToGroup(newGroupId.current, selectedProduct, overrideSizeMismatch);
        setSelectedProduct([]);
      }
      handleShowModal();
      setValue(TabsValue.THREE);
      await fetchGroups();
      newGroupId.current = '';
    } catch (error: any) {
      if (error?.response?.status === 400 && handleErrorMessage(error) === PRODUCTS_DO_NOT_HAVE_SAME_SIZE) {
        setModalContentType(ModalContentType.OVERRIDE);
      } else {
        callToast(ToastTypes.ERROR, handleErrorMessage(error));
      }
    } finally {
      setButtonDisabled(false);
    }
  };
  const handleTechPackData = async (groupId = '', expand: boolean) => {
    setGroups([...modifyGroupsData(groups, groupId, expand)]);
    if (!expand) {
      return;
    }
    setChildLoading(true);
    try {
      const response = await getTechPackData(groupId);
      if (!Object.keys(response?.data?.sizes)?.length) {
        const res = await fetchGroupSizes(response?.data?.id);
        if (!res?.data?.productSizesMatch) {
          setSizesMatch(res?.data?.productSizesMatch);
          setTechPackRes(response?.data);
          setChildButtonDisabled(true);
          return;
        }
        setSizesMatch(res?.data?.productSizesMatch);
        setTechPackRes(addSizesInTechpack(response?.data, Object.keys(res?.data?.productCountBySize)?.[0]?.split(',') ?? []));
        setChildButtonDisabled(true);
      } else {
        setTechPackRes(response?.data);
      }
    } catch (error: any) {
      setChildButtonDisabled(true);
      callToast(ToastTypes.ERROR, handleErrorMessage(error));
    } finally {
      setChildLoading(false);
    }
  };
  const handleTechPackSubmit = async () => {
    setChildButtonDisabled(true);
    try {
      if (techPackRes) {
        await submitTechPackData(techPackRes);
      }
    } catch (error: any) {
      callToast(ToastTypes.ERROR, handleErrorMessage(error));
    } finally {
      setChildButtonDisabled(false);
    }
  };
  const fetchGroups = async () => {
    setLoading(true);
    try {
      const res = await getGroups();
      const sorted = res?.data.sort((a: GroupData, b: GroupData) => {
        return Number(b.hasmeasurements) - Number(a.hasmeasurements);
      });
      setGroups(modifyGroupsData(sorted));
    } catch (error: any) {
      callToast(ToastTypes.ERROR, handleErrorMessage(error));
    } finally {
      setLoading(false);
    }
  };

  const fetchProducts = async (requestUrl = '', type = URL_TYPE.NEXT) => {
    setLoading(true);
    try {
      const res = await getAllProducts(requestUrl, type);
      const data = res?.data?.products;
      setButtonDisabled(false);
      setShowCase(SHOW_CASE.ALL_PRODUCTS);
      setAllProducts((prev: any) => [...prev, ...data]);
      setProductPageInfo({ ...res?.data?.pageInfo });
      if (!groups.length) {
        await fetchGroups();
      }
    } catch (error: any) {
      callToast(ToastTypes.ERROR, handleErrorMessage(error));
    } finally {
      setLoading(false);
    }
  };

  const handleProductOnBoard = async (onboarded: boolean, shopifyProductId: string, type: PRODUCT_TYPE, collectionId = '') => {
    setChildButtonDisabled(true);
    try {
      await onboardProduct([{ shopifyProductId, onboarded }]);
      if (type === PRODUCT_TYPE.PRODUCTS) {
        setAllProducts(updateProductStatus(allProducts, onboarded, shopifyProductId));
      } else {
        const data = updateProductStatus(allCollections?.[collectionId]?.child, onboarded, shopifyProductId);
        allCollections[collectionId].child = data;
        setAllCollections({ ...allCollections });
      }
    } catch (error: any) {
      callToast(ToastTypes.ERROR, handleErrorMessage(error));
    } finally {
      setChildButtonDisabled(false);
    }
  };

  const fetchCollections = async (requestUrl = '', type = URL_TYPE.NEXT) => {
    setCollectionLoading(true);
    try {
      const collections = await getListCollections(requestUrl, type);
      const collectionData: any = {};
      collections?.map((el: any) => {
        collectionData[el?.collectionId] = { value: el, child: [], toggleAll: false, expand: false };
      });
      setAllCollections(collectionData);
    } catch (error: any) {
      callToast(ToastTypes.ERROR, handleErrorMessage(error));
    } finally {
      setCollectionLoading(false);
    }
  };
  const fetchCollectionProducts = async ({ collectionId = '', requestUrl = '', expand }: { collectionId: string; requestUrl?: string; expand?: boolean }) => {
    setSelectedProduct([]);
    if (!requestUrl) {
      setAllCollections({ ...modifyCollection(allCollections, collectionId, expand) });
    }
    if (!expand) {
      return;
    }
    setChildLoading(true);
    try {
      const res = await getCollectionsProduct(collectionId, requestUrl);
      const data = res?.data?.products;
      const prevData = allCollections[collectionId].child;
      allCollections[collectionId].child = [...prevData, ...data];
      setAllCollections({ ...allCollections });
      setProductPageInfo({ ...res?.data?.pageInfo });
    } catch (error: any) {
      callToast(ToastTypes.ERROR, handleErrorMessage(error));
    } finally {
      setChildLoading(false);
    }
  };

  const handleToggleAll = async (flag: boolean, type: PRODUCT_TYPE, colId = '') => {
    setChildButtonDisabled(true);
    let onBoardProductArray: any;
    if (type === PRODUCT_TYPE.PRODUCTS) {
      onBoardProductArray = getOnboardArray(allProducts, flag);
    } else {
      onBoardProductArray = getCollectionsOnboardArray(onBoardedCollectionObj.current);
    }
    try {
      await onboardProduct(onBoardProductArray);
      if (type === PRODUCT_TYPE.PRODUCTS) {
        productAllToggle.current = flag;
        setAllProducts([...updateAll(allProducts, flag)]);
      } else {
        allCollections[colId].toggleAll = flag;
        const data = updateAll(allCollections[colId]?.child, flag);
        allCollections[colId].child = data;
        setAllCollections({ ...allCollections });
      }
    } catch (error: any) {
      callToast(ToastTypes.ERROR, handleErrorMessage(error));
    } finally {
      setChildButtonDisabled(false);
    }
  };
  const handleOnboardGroup = async () => {
    setButtonDisabled(true);
    productAllToggle.current = storeToggle.current;
    try {
      await groupOnboard(storeId.current, storeToggle.current);
      setShowModal(!showModal);
      await fetchGroups();
    } catch (error: any) {
      productAllToggle.current = !productAllToggle.current;
      callToast(ToastTypes.ERROR, handleErrorMessage(error));
    } finally {
      setButtonDisabled(false);
    }
  };

  const fetchGroupProducts = async (id: string, requestUrl = '', type = URL_TYPE.NEXT) => {
    setLoading(true);
    try {
      const res = await getGroupProducts(id, requestUrl, type);
      const data = res?.data?.products;
      setAllProducts((prev: any) => [...prev, ...data]);
      setProductPageInfo({ ...res?.data?.pageInfo });
    } catch (error: any) {
      callToast(ToastTypes.ERROR, handleErrorMessage(error));
    } finally {
      setLoading(false);
    }
  };
  const handleDeleteGroup = async () => {
    setButtonDisabled(true);
    try {
      await deleteGroup(storeId.current);
      handleShowModal();
      setValue(TabsValue.THREE);
      await fetchGroups();
    } catch (error: any) {
      callToast(ToastTypes.ERROR, handleErrorMessage(error));
    } finally {
      setButtonDisabled(false);
    }
  };
  const handleModifyGroup = async (name: string) => {
    setButtonDisabled(true);
    try {
      await modifyGroup({ id: storeId.current, name });
      const modifyGroupArray = groups?.map((el: GroupData) => {
        if (el?.id === storeId?.current) {
          el.name = name;
        }
        return el;
      });
      setGroups(modifyGroupArray);
      setGroupDetail({ id: storeId.current, name });
      const a = allProducts?.map((el: any) => {
        el.techpackName = name;
        return el;
      });
      setAllProducts(a);
      setShowModal(!showModal);
    } catch (error: any) {
      callToast(ToastTypes.ERROR, handleErrorMessage(error));
    } finally {
      setButtonDisabled(false);
    }
  };
  const handleRemoveProductsFromGroup = async () => {
    setButtonDisabled(true);
    try {
      await removeProductsFromGroup(value, selectedProduct);
      const filteredProducts = allProducts?.filter((el: any) => !selectedProduct?.some((id: string) => id === el?.shopifyProductId));
      setAllProducts(filteredProducts);
      setSelectedProduct([]);
    } catch (error: any) {
      callToast(ToastTypes.ERROR, handleErrorMessage(error));
    } finally {
      setButtonDisabled(false);
    }
  };
  const handleAddProductsToGroup = async (id: string) => {
    setButtonDisabled(true);
    try {
      await addProductsToGroup(id, [storeId.current], false);
      storeId.current = '';
      handleShowModal();
      await fetchGroups();
      setValue(TabsValue.THREE);
    } catch (error) {
      callToast(ToastTypes.ERROR, handleErrorMessage(error));
    } finally {
      setButtonDisabled(false);
    }
  };

  useEffect(() => {
    fetchProducts();
    return () => {
      clearTimeout(allToggleTimerId);
      clearTimeout(timerId);
      clearTimeout(productsTimerId);
    };
  }, []);

  return (
    <>
      <div className={`dflex ${styles.onboarding}`}>
        <div className={` ${styles.onboarding__left}`}>
          <div className={styles.onboarding__scrollable}>
            <SubHeader showCase={showCase} groups={groups} />
            <div className={` ${styles.flexWrapper}`}>
              <MeasurementChildContext.Provider
                value={{
                  fetchGroups,
                  fetchProducts,
                  handleProductOnBoard,
                  fetchCollections,
                  handleToggleAll,
                  handleShowModal,
                  handleFileUploaded,
                  fileFilter,
                  productAllToggle: productAllToggle.current,
                  fetchGroupProducts,
                  buttonDisabled: !showModal && buttonDisabled,
                  handleTabsChange,
                  handleRemoveProductsFromGroup,
                  handleTechPackData,
                  handleTechPackSubmit,
                  fetchCollectionProducts,
                  uploadMultipleFiles,
                  handleDeleteFile,
                }}
              >
                <ChildFunction />
              </MeasurementChildContext.Provider>
            </div>
            <div className={`  ${showCase !== SHOW_CASE.ALL_PRODUCTS ? `justifySpaceBetween` : `justifyEnd`}  ${styles.footerNext}`}>
              {showCase !== SHOW_CASE.NONE && (
                <>
                  {showCase !== SHOW_CASE.ALL_PRODUCTS && <CustomButton buttonText={'Back'} className={'button'} handleFunc={handleBack} />}
                  <CustomButton buttonText={showCase === SHOW_CASE.FILE_UPLOAD_TYPE ? 'Submit' : 'Next'} disabled={buttonDisabled} className={'button'} handleFunc={handleNext} />
                </>
              )}
            </div>
          </div>
        </div>
        <div className={` ${styles.onboarding__right}`}>
          <MeasurementOnBoarding progress={progress} handleProgressBar={handleProgressBar} isInternalUser={isInternalUser} />
        </div>

        <CustomModal maxWidth={'464px'} showModal={showModal} handleShowModal={handleShowModal}>
          <CreateGroup
            handleShowModal={handleShowModal}
            handleCreateGroup={handleCreateGroup}
            buttonDisabled={buttonDisabled}
            modalContentType={modalContentType}
            groups={groups}
            handleDeleteGroup={handleDeleteGroup}
            handleOnboardGroup={handleOnboardGroup}
            handleAddProductsToGroup={handleAddProductsToGroup}
            handleModifyGroup={handleModifyGroup}
            storeToggle={storeToggle.current}
          />
        </CustomModal>
      </div>
    </>
  );
};

export default Measurements;
