import { SelectChangeEvent } from '@mui/material';
import CustomButton from 'component/CustomButton';
import NoDataPlaceHolder from 'component/noDataPlaceholder/NoPlaceHolder';
import TechPackTable from 'component/table/TechPackTable';
import { PRODUCTS_DO_NOT_HAVE_SAME_SIZE, RowOptions } from 'constants/constants';
import { AlterType, ModalContentType, TechPackChildren, TechPackKeys } from 'enum/enum';
import { GroupData, MeasurementContextInterface, RowsData } from 'interface/interface';
import React, { ChangeEvent, useContext, useEffect, useRef, useState } from 'react';
import { deepCopy, filterRowOptions, getRowsAndAllowColumns, updateTableRows, updateTechPackData } from 'utils/utils';
import styles from './ReviewTable.module.scss';
import ReviewTableDescription from './ReviewTableDescription';
import { MeasurementContext } from 'context/MeasurementContext';
import { MeasurementChildContext } from 'context/MeasurementChildContext';
import LoadingScreen from 'component/LoadingScreen';

const ReviewTable = () => {
  const {
    techPackRes,
    setTechPackRes,
    childLoading,
    groups,
    sizesMatch,
    loading,
    setChildButtonDisabled: setTechPackButtonDisabled,
    childButtonDisabled: techPackButtonDisabled,
  } = useContext(MeasurementContext) as MeasurementContextInterface;
  const { handleTechPackData, handleShowModal, handleTechPackSubmit } = useContext(MeasurementChildContext) as any;
  const [rows, setRows] = useState<RowsData[]>([]);
  const [showOptions, setShowOptions] = useState<boolean>(false);
  const [rowOptions, setRowOptions] = useState<Record<string, any>[]>(deepCopy(RowOptions));
  const selectedOptionObj = useRef<Record<string, boolean>>({});
  // const [value, setValue] = useState<GroupData>();
  // const handleTabChange = (newValue: GroupData) => {
  //   setValue(newValue);
  //   handleTechPackData(newValue?.id);
  // };

  const handleShowOptions = () => {
    if (showOptions && Object.keys(selectedOptionObj.current).length > 0) {
      const updatedOptions = rowOptions.map((el) => {
        if (el?.name in selectedOptionObj.current && selectedOptionObj.current[el?.name] === true) {
          el.selected = selectedOptionObj.current[el?.name];
        } else {
          delete selectedOptionObj.current[el?.name];
        }
        return el;
      });

      setRowOptions([...updatedOptions]);
      setRows([...updateTableRows(rows, selectedOptionObj.current)]);
      if (techPackRes) {
        setTechPackRes(updateTechPackData(techPackRes, selectedOptionObj.current));
      }
      selectedOptionObj.current = {};
    }
    setShowOptions(!showOptions);
  };
  const handleAllowEditColumn = (id: string, type: AlterType, save = false) => {
    const index = rows?.findIndex((el: RowsData) => el.id === id);

    if (type === AlterType.ONLY_EDIT) {
      rows[index].notEdit = false;
      setRows([...rows]);
    } else if (type === AlterType.EDIT) {
      rows[index].notEdit = !rows[index].notEdit;
      setRows([...rows]);
    }
    if (save) {
      handleTechPackSubmit();
    }
  };

  const handleOnChange = (event: ChangeEvent<HTMLInputElement>, parent: TechPackChildren) => {
    const { id, name, value } = event.target;
    if (techPackRes !== undefined) {
      techPackRes[parent][id][name] = value;
      setTechPackRes({ ...techPackRes });
    }
  };

  const handleBaseSize = (event: SelectChangeEvent | ChangeEvent<HTMLTextAreaElement>, type: TechPackKeys) => {
    const { value } = event.target;
    if (techPackRes !== undefined) {
      if (type === TechPackKeys.BASE_SIZE) {
        if (isNaN(+value)) {
          techPackRes.base_size = value;
        } else {
          techPackRes.base_size = +value;
        }
        const rest = Object.values(techPackRes.ease)[0];
        techPackRes.ease = { [value]: rest ?? {} };
      } else if (type === TechPackKeys.CATEGORY) {
        techPackRes[TechPackKeys.CATEGORY] = value;
      } else if (type === TechPackKeys.DIVISION) {
        techPackRes[TechPackKeys.DIVISION] = value;
      } else {
        techPackRes[TechPackKeys.DESCRIPTION] = value;
      }
      setTechPackRes({ ...techPackRes });
    }
  };
  const handleDeleteRow = async (rowName: string, id: string) => {
    if (techPackRes) {
      for (const key in techPackRes?.sizes) {
        if (rowName in techPackRes.sizes[key]) {
          techPackRes.sizes[key][rowName] = null;
        }
      }
      for (const key in techPackRes?.ease) {
        if (rowName in techPackRes.ease[key]) {
          techPackRes.ease[key][rowName] = null;
        }
      }
    }

    const updatedRows = RowOptions?.map((el: Record<string, any>) => {
      if (el?.name === rowName) {
        el.selected = false;
      }
      return el;
    });
    const filteredRows = rows?.filter((el: RowsData) => el?.id !== id);
    setRowOptions(updatedRows);
    setRows([...filteredRows]);
    if (techPackRes !== undefined) {
      setTechPackRes({ ...techPackRes });
      await handleTechPackSubmit();
    }
  };

  const handleSelectedRow = (name: string, value: boolean) => {
    selectedOptionObj.current[name] = value;
  };
  useEffect(() => {
    if (!childLoading && techPackRes?.sizes && Object.keys(techPackRes?.sizes).length > 0) {
      setRows(getRowsAndAllowColumns(techPackRes));
    }
  }, [childLoading]);

  useEffect(() => {
    if (rows?.length) {
      setTechPackButtonDisabled(false);
    } else {
      setTechPackButtonDisabled(true);
    }

    setRowOptions(filterRowOptions(RowOptions, rows));
  }, [rows]);

  return (
    <>
      {loading ? (
        <div className={` ${styles.review__loader}`}>
          <div className="dflex alignCenter justifyCenter w-full">
            <LoadingScreen />
          </div>
        </div>
      ) : groups?.length > 0 ? (
        groups?.map((group: GroupData) => (
          <React.Fragment key={group?.id}>
            <div className={`flex alignCenter justifySpaceBetween ${styles.groupName}`}>
              <h5>
                {group?.name} <span>{group?.productscount} products</span>{' '}
                {!group?.hasmeasurements && <span className={`${!group?.hasmeasurements && styles.groupNameBgRed}`}>missing techpack</span>}
              </h5>

              <div className={`flex alignCenter ${styles.groupName__button}`}>
                <span
                  className={childLoading ? 'disabled-link' : ''}
                  onClick={() => {
                    handleTechPackData(group?.id, !group?.expand);
                    const options = RowOptions?.map((el) => {
                      el.selected = false;
                      return el;
                    });
                    setRowOptions(options);
                    setShowOptions(false);
                  }}
                >
                  {group?.expand ? 'Collapse table' : 'Expand table'}
                </span>
                <CustomButton
                  buttonText={group?.productscount === group?.onboardedproductscount ? 'Pause' : 'Live'}
                  className="button"
                  disabled={!group?.hasmeasurements}
                  handleFunc={() => handleShowModal(ModalContentType.ONBOARD, group?.id, !(group?.productscount === group?.onboardedproductscount))}
                />
              </div>
            </div>
            {group?.expand && (
              <>
                {childLoading ? (
                  <div className="dflex alignCenter justifyCenter w-full">
                    <LoadingScreen />
                  </div>
                ) : (
                  <div className={` ${styles.review}`}>
                    {techPackRes?.sizes && Object.keys(techPackRes?.sizes).length > 0 ? (
                      <>
                        <ReviewTableDescription
                          handleBaseSize={handleBaseSize}
                          handleShowOptions={handleShowOptions}
                          showOptions={showOptions}
                          techPackRes={techPackRes}
                          rowOptions={rowOptions}
                          handleSelectedRow={handleSelectedRow}
                        />
                        <div className={`reviewTable ${styles.review__table}`}>
                          <TechPackTable
                            handleOnChange={handleOnChange}
                            techPackRes={techPackRes}
                            handleDeleteRow={handleDeleteRow}
                            handleAllowEditColumn={handleAllowEditColumn}
                            rows={rows}
                            techPackButtonDisabled={techPackButtonDisabled}
                          />
                          {/* <div className={styles.review__tableButton}>
                            <CustomButton buttonText={'save'} className="button" disabled={techPackButtonDisabled} handleFunc={handleTechPackSubmit} />
                          </div> */}
                        </div>
                      </>
                    ) : (
                      <div className="dflex alignCenter flexDirectionCol justifyCenter w-full">
                        <NoDataPlaceHolder message="No techpack found in this list">{!sizesMatch ? <p>{PRODUCTS_DO_NOT_HAVE_SAME_SIZE}</p> : <></>}</NoDataPlaceHolder>
                      </div>
                    )}
                  </div>
                )}
              </>
            )}
          </React.Fragment>
        ))
      ) : (
        <div className="dflex alignCenter justifyCenter w-full">
          <NoDataPlaceHolder message="No Group found in this list" />
        </div>
      )}
    </>
  );
};

export default ReviewTable;
