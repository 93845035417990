import { API_END_POINTS, FULFILLED } from 'constants/constants';
import UseApiService from './apiService';
import { CheckStatusType, TOKEN_DATA_TYPE } from 'enum/enum';
import axios from 'axios';
import { ApprovedNotes, FileUploadData } from 'interface/interface';
import { handlePromiseArray } from 'utils/utils';

export const urlGenerator = async (fileName: string, groupId = '', uploadType: string) => {
  const body: any = {
    url: API_END_POINTS.UPLOAD_URL,
    data: {
      file: fileName,
      uploadType,
      groupId,
    },
    headerToken: TOKEN_DATA_TYPE.ACCESS,
  };
  return UseApiService().get(body);
};

export const uploadFileAWSBucket = (file: File, url: string) => {
  return axios.put(url, file, {
    headers: {
      'Content-Type': file?.type,
    },
  });
};

export const uploadNotes = (data: ApprovedNotes) => {
  const body = {
    url: API_END_POINTS.NOTES,
    data: {
      note: data.notes,
      approved: data.approved,
    },
    headerToken: TOKEN_DATA_TYPE.ACCESS,
  };
  return UseApiService().post(body);
};

export const UploadFileWithNote = (fileName: string, noteId: string, groupId: string) => {
  const body = {
    url: API_END_POINTS.UPLOAD_URL,
    data: {
      file: fileName,
      noteId,
      groupId,
    },
    headerToken: TOKEN_DATA_TYPE.ACCESS,
  };
  return UseApiService().get(body);
};

export const fileUploadUrlPromiseArray = async ({
  files,
  noteId = '',
  groupId = '',
  uploadType = 'techpack',
}: {
  files: FileUploadData[];
  noteId?: string;
  groupId?: string;
  uploadType?: string;
}) => {
  let promiseArray = [];
  if (noteId) {
    promiseArray = files?.reduce((ac: any[], el: FileUploadData) => {
      if (el.uploaded === false && el.show === true) {
        ac.push(UploadFileWithNote(el.file.name, noteId, groupId));
      }
      return ac;
    }, []);
  } else {
    promiseArray = files?.reduce((ac: any[], el: FileUploadData) => {
      if (el.uploaded === false && el.show === true) {
        ac.push(urlGenerator(el.file.name, groupId, uploadType));
      }
      return ac;
    }, []);
  }
  const res = await handlePromiseArray(promiseArray);
  const statusCheckRes = await checkStatusModifyFiles(res, files, CheckStatusType.GET_PROMISE_ARRAY);
  const promiseRes = await handlePromiseArray(statusCheckRes?.promiseArray);
  const newStatusCheckRes = await checkStatusModifyFiles(promiseRes, statusCheckRes?.newFiles, CheckStatusType.GET_FILES_ARRAY);
  return newStatusCheckRes?.newFiles;
};
export const checkStatusModifyFiles = async (resolvedArray: any[], files: FileUploadData[], type: CheckStatusType) => {
  const newFiles = [...files];
  const promiseArray: any[] = [];
  if (type === CheckStatusType.GET_PROMISE_ARRAY) {
    resolvedArray?.forEach((result, index) => {
      if (result?.status === FULFILLED) {
        const data = result?.value?.data;
        promiseArray.push(uploadFileAWSBucket(files[index].file, data?.uploadUrl));
      } else {
        files[index].show = false;
      }
    });
    return { promiseArray, newFiles };
  } else {
    resolvedArray?.forEach((result, index) => {
      if (result?.status === FULFILLED) {
        innerLoop: for (const e of files) {
          if (e.show === true && e.uploaded === false) {
            files[index].show = true;
            files[index].uploaded = true;
            files[index].showDelete = true;
            files[index].showProgress = false;
            break innerLoop;
          }
        }
      }
    });
  }
  return { promiseArray, newFiles };
};

export const getUploadedFiles = (groupId: string, uploadType = 'techpack') => {
  const body = {
    url: API_END_POINTS.FILES_LIST,
    data: {
      uploadType,
      groupId,
    },
    headerToken: TOKEN_DATA_TYPE.ACCESS,
  };
  return UseApiService().get(body);
};

export const deleteFile = (file: string, groupId: string, uploadType = 'techpack') => {
  const body = {
    url: API_END_POINTS.DELETE_FILE,
    data: {
      uploadType,
      groupId,
      file,
    },
    headerToken: TOKEN_DATA_TYPE.ACCESS,
  };
  return UseApiService().delete(body);
};

export const exportScanData = (scanId: string, email = '') => {
  const body = {
    url: API_END_POINTS.CUSTOM_FIT_EXPORT,
    params: {
      scanId,
      email,
    },
    headerToken: TOKEN_DATA_TYPE.ACCESS,
  };
  return UseApiService().post(body);
};

export const customUploadNotes = (data: string, scanId: string) => {
  const body = {
    url: `${API_END_POINTS.CUSTOM_FIT}${API_END_POINTS.NOTES}?scanId=${scanId}`,
    data: {
      notes: data,
    },
    headerToken: TOKEN_DATA_TYPE.ACCESS,
  };
  return UseApiService().post(body);
};

export const customMeasurementsUpdate = (data: string, scanId: string) => {
  const body = {
    url: `${API_END_POINTS.CUSTOM_FIT}${API_END_POINTS.MEASUREMENTS}?scanId=${scanId}`,
    data: {
      measurements: data,
    },
    headerToken: TOKEN_DATA_TYPE.ACCESS,
  };
  return UseApiService().post(body);
};
