import React, { ChangeEvent, useState } from 'react';
import closeIcon from 'assets/images/x-close-gray.svg';
import warningIcon from 'assets/images/warning.svg';
import flagIcon from 'assets/images/flag.svg';
import style from './CreateGroup.module.scss';
import CustomButton from 'component/CustomButton';
import { ModalContentType } from 'enum/enum';
import CustomDropdown from 'component/customDropdown/CustomDropdown';
import { CreateGroupProps, GroupData } from 'interface/interface';
import { NONE, PRODUCTS_DO_NOT_HAVE_SAME_SIZE } from 'constants/constants';
// import { groupCategory, groupDivision } from 'constants/constants';
// import CustomDropdown from 'component/customDropdown/CustomDropdown';

const CreateGroup = ({
  handleShowModal,
  handleCreateGroup,
  buttonDisabled,
  modalContentType,
  groups,
  handleModifyGroup,
  handleAddProductsToGroup,
  handleOnboardGroup,
  storeToggle,
  handleDeleteGroup,
}: CreateGroupProps) => {
  const [groupName, setGroupName] = useState<string>('');
  const [groupDetail, setGroupDetails] = useState<GroupData>();

  const handleGroupsApi = () => {
    if (ModalContentType.CREATE === modalContentType) {
      handleCreateGroup({ groupName });
    } else if (ModalContentType.EDIT === modalContentType) {
      handleModifyGroup(groupName);
    } else if (ModalContentType.CHANGE === modalContentType) {
      if (groupDetail) {
        handleAddProductsToGroup(groupDetail?.id);
      }
    } else if (ModalContentType.ONBOARD === modalContentType) {
      handleOnboardGroup();
    } else if (ModalContentType.DELETE === modalContentType) {
      handleDeleteGroup();
    } else {
      handleCreateGroup({ overrideSizeMismatch: true });
    }
  };

  return (
    <div className={style.createGroup}>
      <div className={`${style.createGroup__header} flex alignStart justifySpaceBetween`}>
        <picture className="flex alignCenter justifyCenter">
          <img src={modalContentType === ModalContentType.OVERRIDE || modalContentType === ModalContentType.DELETE ? warningIcon : flagIcon} alt="Flag" />
        </picture>
        <img src={closeIcon} alt="Close" onClick={() => handleShowModal()} />
      </div>
      {(() => {
        switch (modalContentType) {
          case ModalContentType.CHANGE: {
            return (
              <>
                <h4>Choose a group </h4>
                <div className={style.createGroup__input}>
                  <label>Group name</label>
                  <CustomDropdown value={groupDetail ?? NONE} optionList={groups} handleOnChange={(e) => setGroupDetails(e.target.value)} text="Select Group" />
                  {/* <label>Base Size</label>
            <CustomDropdown value={groupDetail} optionList={groups} handleOnChange={(e) => setGroupDetails(e.target.value)} />
            <label>Category</label>
            <CustomDropdown value={groupName} optionList={groupCategory} handleOnChange={(e) => setGroupDetails(e.target.value)} />
            <label>Division</label>
            <CustomDropdown value={groupName} optionList={groupDivision} handleOnChange={(e) => setGroupDetails(e.target.value)} /> */}
                </div>
              </>
            );
          }
          case ModalContentType.CREATE: {
            return (
              <>
                <h4>Create a group </h4>
                <div className={style.createGroup__input}>
                  <label>Group name</label>
                  <input
                    className={style.createGroup__inputItem}
                    placeholder={'e.g. shirts'}
                    value={groupName}
                    onChange={(e: ChangeEvent<HTMLInputElement>) => setGroupName(e.target.value)}
                  />{' '}
                </div>
              </>
            );
          }
          case ModalContentType.EDIT: {
            return (
              <>
                <h4>Rename a group </h4>
                <div className={style.createGroup__input}>
                  <label>Group name</label>
                  <input
                    className={style.createGroup__inputItem}
                    placeholder={'e.g. shirts'}
                    value={groupName}
                    onChange={(e: ChangeEvent<HTMLInputElement>) => setGroupName(e.target.value)}
                  />{' '}
                </div>
              </>
            );
          }
          case ModalContentType.ONBOARD: {
            return (
              <>
                <h4>Onboarding!</h4>
                <div className={style.createGroup__input}>
                  <label>{storeToggle ? 'Do you want to onboard all the products?' : 'Do you want to remove all the products from onboarding?'}</label>
                  {/* <input
                    className={style.createGroup__inputItem}
                    placeholder={'e.g. shirts'}
                    value={groupName}
                    onChange={(e: ChangeEvent<HTMLInputElement>) => setGroupName(e.target.value)}
                  />{' '} */}
                </div>
              </>
            );
          }
          case ModalContentType.DELETE: {
            return (
              <>
                <h4>Warning!</h4>
                <div className={style.createGroup__input}>
                  <label>Want to delete this group?</label>
                  {/* <input
                    className={style.createGroup__inputItem}
                    placeholder={'e.g. shirts'}
                    value={groupName}
                    onChange={(e: ChangeEvent<HTMLInputElement>) => setGroupName(e.target.value)}
                  />{' '} */}
                </div>
              </>
            );
          }
          default: {
            return (
              <>
                <h4>Warning!</h4>
                <div className={style.createGroup__input}>
                  <label>{PRODUCTS_DO_NOT_HAVE_SAME_SIZE}.Do you want continue?</label>
                </div>
              </>
            );
          }
        }
      })()}

      <div className={`${style.createGroup__button} flex`}>
        <CustomButton buttonText="Cancel" className="button outline black" handleFunc={() => handleShowModal()} disabled={buttonDisabled} />
        <CustomButton
          className="button"
          buttonText={modalContentType === ModalContentType.OVERRIDE ? 'Override' : 'Confirm'}
          handleFunc={handleGroupsApi}
          loading={buttonDisabled}
          disabled={buttonDisabled || (!groupName.trim() && ![ModalContentType.CHANGE, ModalContentType.ONBOARD, ModalContentType.DELETE].includes(modalContentType))}
        />
      </div>
    </div>
  );
};
export default CreateGroup;
