import React from 'react';
import styles from './SubHeader.module.scss';
// import dot from 'assets/images/dots-vertical.svg';
import { SHOW_CASE } from 'enum/enum';
import { GroupData } from 'interface/interface';

const SubHeader = ({ showCase }: { showCase: SHOW_CASE; groups: GroupData[] }) => {
  return (
    <div className={`${styles.subheader}`}>
      <div className="flex justifySpaceBetween alignStart">
        <div>
          {(() => {
            switch (showCase) {
              case SHOW_CASE.AUTH: {
                return <h1>Let’s start your measurement onboarding.</h1>;
              }
              case SHOW_CASE.LOAD_PRODUCT: {
                return <h1>Let’s start your measurement onboarding.</h1>;
              }
              case SHOW_CASE.ALL_PRODUCTS: {
                return (
                  <>
                    <h1>Product selection</h1>
                    <p>Below are all the products live on your site. Please toggle off those which you do not want supported by Swan.</p>
                    <p>Tip: if unsure which to support, remove the products for which you don’t have measurements for.</p>
                  </>
                );
              }
              case SHOW_CASE.FILE_UPLOAD_TYPE: {
                return (
                  <>
                    <h1>Measurement upload</h1>
                    {/* <p>Now that you’ve chosen which products you want to support with Swan. Upload all your tech packs (with both inputs) below.</p>
                    <p>
                      Tip: Name the files the same as your products/product categories. If one fit model covers all products add a note to say so & specify the base size for each
                      product.
                    </p> */}
                  </>
                );
              }
              case SHOW_CASE.REVIEW_TABLE: {
                return (
                  <>
                    <h1>Review</h1>
                    <p> Here are your parsed files. Add any missing details & push them live.</p>
                  </>
                );
              }
              default: {
                return '';
              }
            }
          })()}
        </div>
        {/* <img src={dot} alt="menu" role="cursor" /> */}
      </div>
    </div>
  );
};

export default SubHeader;
