import React from 'react';
import CustomDropdown from '../customDropdown/CustomDropdown';
import CustomButton from '../CustomButton';
import styles from './ApiKeyGenerateModal.module.scss';
import Input from '../input/Input';
import { useForm } from 'react-hook-form';
import copyIcon from '../../assets/images/copy-icon-white.svg';

interface ApiKeyGenerateModalProps {
  apiKey: string;
  handleShowModal: () => void;
  loading: boolean;
  generateApiKey: () => Promise<void>;
  copyKey: (id: string) => Promise<void>;
}

const ApiKeyGenerateModal = ({ apiKey, handleShowModal, loading, generateApiKey, copyKey }: ApiKeyGenerateModalProps) => {
  const { register } = useForm({});
  return apiKey ? (
    <div className={` ${styles.modal}`}>
      <h2>Save your key</h2>
      <p>
        Please save this secret key somewhere safe and accessible. For security reasons, <strong>you won&apos;t be able to view it</strong> again through your OpenAI account. If
        you lose this secret key, you&apos;ll need to generate a new one.
      </p>
      <div className={` dflex alignCenter ${styles.modal__upload}`}>
        <input readOnly placeholder={apiKey} />
        <CustomButton buttonImg={copyIcon} id={'key-copy-button'} buttonText={'copy'} className="button" handleFunc={() => copyKey(apiKey)} />
      </div>
      <h4>Permissions</h4>
      <p>Read and write API resources</p>
      <div className={` ${styles.modal__button} dflex justifyEnd`}>
        <CustomButton buttonText={'Done'} className={'button'} handleFunc={handleShowModal} />
      </div>
    </div>
  ) : (
    <div className={` ${styles.modal}`}>
      <h2>Create new secret key</h2>

      <p>Name Optional</p>
      <Input type="text" placeholder="Personal" inputName="brandName" register={register} />
      <p>Permission</p>
      <CustomDropdown value={'All'} optionList={['All', 'Read only', 'Restricted']} handleOnChange={(e) => console.log(e.target.value)} />
      <div className={` ${styles.modal__button} dflex justifyEnd`}>
        <CustomButton className={'button'} buttonText="Cancel" handleFunc={handleShowModal} disabled={loading} />
        <CustomButton className={'button'} buttonText="Create secret key‍" handleFunc={generateApiKey} disabled={loading} loading={loading} />
      </div>
    </div>
  );
};

export default ApiKeyGenerateModal;
