/* eslint-disable max-lines */
import { AuthContext } from 'context/AuthContext';
import { DATE_PICKER_VALUE, FETCH_LIST_TYPE } from 'enum/enum';
import { AuthContextInterface } from 'interface/interface';
import React, { useContext, useEffect, useState } from 'react';
import { fetchList, getProductsSupported } from 'services/listService';
import styles from './Analytics.module.scss';
import PageTitle from 'component/pageTitle/PageTitle';
import { FULFILLED, REACT_APP_POSTHOG_LIVE_PROJECT_ID } from 'constants/constants';
import { getDate, getFilteredSizingSuggestionListLength, removeUnwantedEmails } from 'utils/utils';
import AnalyticsChild from './AnalyticsChild';
import LoadingScreen from 'component/LoadingScreen';
import CustomDialogModal from 'component/CustomDialogModal';
import DateFilter from 'component/dateFilter/DateFilter';

const Analytics = () => {
  const [datePickerValue, setDatePickerValue] = useState<DATE_PICKER_VALUE>(DATE_PICKER_VALUE.TODAY);
  const [scanUsersList, setScanUsersList] = useState<any[]>([]);
  const [loading, setLoading] = useState<boolean>(true);
  const [noOfSizingSuggestions, setNoOfSizingSuggestions] = useState<number>(0);
  const [productDetails, setProductDetails] = useState<any>({});
  const [measurementSuccessData, setMeasurementSuccessData] = useState<any[]>([]);
  const [scanStartedData, setScanStartedData] = useState<any[]>([]);
  const [reScanCount, setReScanCount] = useState<number>(0);
  const [confirmationRate, setConfirmationRate] = useState<any[]>([]);
  const { userDetails } = useContext(AuthContext) as AuthContextInterface;
  const [open, setOpen] = useState(false);
  const [startDate, setStartDate] = useState<Date>(new Date());
  const [endDate, setEndDate] = useState(new Date());

  const handleShowModal = () => {
    setOpen(!open);
  };

  const fetchAllList = async (custom = false) => {
    setLoading(true);
    try {
      let after: DATE_PICKER_VALUE | string = datePickerValue;
      let before = '';
      if (custom) {
        after = getDate(startDate);
        before = endDate ? getDate(endDate) : '';
      }
      const fetchPromisesCombined = [
        fetchList({ projectId: REACT_APP_POSTHOG_LIVE_PROJECT_ID ?? '', after, event: userDetails?.store_url, before }),
        getProductsSupported(),
        fetchList({ projectId: REACT_APP_POSTHOG_LIVE_PROJECT_ID ?? '', after, event: FETCH_LIST_TYPE.SIZING_SUGGESTIONS, before }),
        fetchList({ projectId: REACT_APP_POSTHOG_LIVE_PROJECT_ID ?? '', after, event: `${userDetails?.store_url}/measurement_success/fit-view`, before }),
        fetchList({ projectId: REACT_APP_POSTHOG_LIVE_PROJECT_ID ?? '', after, event: `${userDetails?.store_url}/scan_started`, before }),
        fetchList({ projectId: REACT_APP_POSTHOG_LIVE_PROJECT_ID ?? '', after, event: `${userDetails?.store_url}/rescan`, before }),
        fetchList({
          projectId: REACT_APP_POSTHOG_LIVE_PROJECT_ID ?? '',
          after: datePickerValue,
          event: `${userDetails?.store_url}/token-verify`,
          extraParams: `"status":"success"`,
        }),
      ];

      const resolvedResults = await Promise.allSettled(fetchPromisesCombined);

      resolvedResults?.forEach((result, index) => {
        if (result?.status === FULFILLED) {
          const data: any = result?.value;
          switch (index) {
            case 0: {
              setScanUsersList(removeUnwantedEmails(data));
              break;
            }
            case 1: {
              setProductDetails(data?.data ?? []);
              break;
            }
            case 2: {
              setNoOfSizingSuggestions(getFilteredSizingSuggestionListLength(data, userDetails?.store_url));
              break;
            }
            case 3: {
              setMeasurementSuccessData(data);
              break;
            }
            case 4: {
              setScanStartedData(data);
              break;
            }
            case 5: {
              setReScanCount(data?.length);
              break;
            }
            default: {
              setConfirmationRate(data);
            }
          }
        }
      });
    } catch (err) {
      console.log('error=', err);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (datePickerValue !== DATE_PICKER_VALUE.CUSTOM) {
      fetchAllList();
    }
  }, [datePickerValue]);

  return (
    <div className={`${styles.listMain}`}>
      <CustomDialogModal
        open={open}
        handleShowModal={handleShowModal}
        startDate={startDate}
        setStartDate={setStartDate}
        endDate={endDate}
        setEndDate={setEndDate}
        setDatePickerValue={setDatePickerValue}
        fetchAllList={fetchAllList}
      />
      <PageTitle name={userDetails?.name} />
      <div className={`${styles.main}`}>
        <div className={`flex justifyEnd ${styles.dateSelect}`}>
          <DateFilter
            setDatePickerValue={setDatePickerValue}
            datePickerValue={datePickerValue}
            handleShowModal={handleShowModal}
            startDate={getDate(startDate)}
            endDate={getDate(endDate)}
          />
        </div>

        {loading ? (
          <LoadingScreen />
        ) : (
          <AnalyticsChild
            usersList={scanUsersList}
            datePickerValue={datePickerValue}
            noOfSizingSuggestions={noOfSizingSuggestions}
            productDetails={productDetails}
            measurementSuccessData={measurementSuccessData}
            scanStartedData={scanStartedData}
            reScanCount={reScanCount}
            confirmationRate={confirmationRate}
          />
        )}
      </div>
    </div>
  );
};

export default Analytics;
