import TableCompoWithStartingCheckBox from 'component/table/tableCompoWithCheckBox/TableCompoWithStartingCheckBox';
import React, { useContext } from 'react';
import edit from 'assets/images/edit.svg';
import style from './GroupCompo.module.scss';
import { ModalContentType, PRODUCT_TYPE } from 'enum/enum';
import RemoveIcon from '../../assets/images/delete.svg';
import CloseIcon from '../../assets/images/close.svg';
import InfiniteScrollComponent from 'component/InfiniteScrollComponent';
import TooltipBlock from 'component/TooltipBlock/TooltipBlock';
import { MeasurementContextInterface } from 'interface/interface';
import { MeasurementContext } from 'context/MeasurementContext';
import { MeasurementChildContext } from 'context/MeasurementChildContext';
import CustomButton from 'component/CustomButton';
import CustomModal from 'component/CustomModal';
import FileUploadModal from 'component/fileUploadModal/FileUploadModal';
import FileUploadType from 'component/fileUploadType/FileUploadType';
import LoadingScreen from 'component/LoadingScreen';

const handleLongTitle = (text: string) => {
  if (text?.length > 200) {
    return text.substring(0, 200) + '...';
  }
  return text;
};
const GroupCompo = ({ handleProductPageApi }: { handleProductPageApi: () => void }) => {
  const {
    setSelectedProduct,
    groupDetail,
    allProducts: data,
    selectedProduct,
    loading,
    multiFiles,
    approvedNotes,
    setApprovedNotes,
    setUploadFileModal,
    uploadFileModal,
    setMultiFiles,
  } = useContext(MeasurementContext) as MeasurementContextInterface;
  const { name, id } = groupDetail;
  const { handleShowModal, handleProductOnBoard, handleRemoveProductsFromGroup, buttonDisabled, handleFileUploaded, fileFilter, uploadMultipleFiles } = useContext(
    MeasurementChildContext
  ) as any;
  const toggleModal = () => {
    setUploadFileModal(!uploadFileModal);
    setMultiFiles([]);
  };

  return (
    <div className={style.groupCompo}>
      <div className={`${style.groupCompo__header} dflex alignCenter justifySpaceBetween`}>
        <div className={`${style.groupCompo__action} flex alignCenter`}>
          {data?.length !== 0 && <CustomButton buttonText="Add files" className={'button'} handleFunc={toggleModal} />}

          <img src={edit} alt="edit" onClick={() => handleShowModal(ModalContentType.EDIT, id)} />

          <button className={style.groupCompo__actionBtn} onClick={() => handleShowModal(ModalContentType.DELETE, id)} disabled={buttonDisabled}>
            <img src={RemoveIcon} alt="" />
          </button>
          {selectedProduct?.length > 0 && (
            <button className={style.groupCompo__actionBtn} onClick={handleRemoveProductsFromGroup} disabled={buttonDisabled}>
              <img src={CloseIcon} alt="" />
            </button>
          )}
        </div>

        <h2 className={`${name?.toString()?.length > 200 ? style.isActive : ''} flex alignCenter`}>
          <TooltipBlock className="text" placement="top" holderContent={name?.toString()?.length > 200 ? name : ''}>
            <>{handleLongTitle(name.toString())}</>
          </TooltipBlock>
          {/* <Switch checked={allToggle} disabled={!data?.length} onChange={(e) => handleShowModal(ModalContentType.ONBOARD, id, e.target.checked)} /> */}
        </h2>
      </div>
      <div>
        {loading && data?.length === 0 ? (
          <div className="dflex alignCenter justifyCenter w-full">
            <LoadingScreen height={false} />
          </div>
        ) : (
          <>
            <div className={style.groupCompo__table}>
              <TableCompoWithStartingCheckBox
                data={data}
                loading={loading}
                selectedProduct={selectedProduct}
                setSelectedProduct={setSelectedProduct}
                handleProductOnBoard={(value, id) => handleProductOnBoard(value, id, PRODUCT_TYPE.PRODUCTS)}
                handleShowModal={handleShowModal}
                showCheckBox={false}
                showToggle={false}
              />
              <InfiniteScrollComponent functionToCall={handleProductPageApi} data={data} />
            </div>
            {data?.length > 0 && <FileUploadType />}
          </>
        )}
      </div>
      <CustomModal showModal={uploadFileModal} handleShowModal={toggleModal}>
        <FileUploadModal
          handledFileUploaded={handleFileUploaded}
          files={multiFiles}
          fileFilter={fileFilter}
          handleShowModal={toggleModal}
          approvedNotes={approvedNotes}
          setApprovedNotes={setApprovedNotes}
          uploadMultipleFiles={uploadMultipleFiles}
          loading={loading}
        />
      </CustomModal>
    </div>
  );
};

export default GroupCompo;
