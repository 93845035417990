import React, { useContext, useEffect, useRef, useState } from 'react';
import styles from './ApiKeys.module.scss';
import CustomButton from 'component/CustomButton';
import Input from 'component/input/Input';
import { useForm } from 'react-hook-form';
import OrganizationService from 'services/organizationService';
import { AuthContext } from 'context/AuthContext';
import { AuthContextInterface, ToastInterface } from 'interface/interface';
import { ToastContext } from 'context/Toast';
import { ToastTypes } from 'enum/enum';
import { copyToClipboard, handleErrorMessage } from 'utils/utils';
import TableCompoWithoutCheckBox from 'component/table/tableCompoWithCheckBox/TableCompoWithoutCheckBox';
import { ApiKeysHeadCell } from 'component/table/tableCompoWithCheckBox/tableData';
import LoadingScreen from 'component/LoadingScreen';
import CustomModal from 'component/CustomModal';
import { TENANT_ID } from 'constants/constants';
import ConfirmKeyDisableModal from 'component/confirmKeyDisableModal/ConfirmKeyDisableModal';
import ApiKeyGenerateModal from 'component/apiKeyGenerateModal/ApiKeyGenerateModal';

const organizationService = new OrganizationService();
const ApiKeys = () => {
  const { register } = useForm({});
  const { orgName } = useContext(AuthContext) as AuthContextInterface;
  const [loading, setLoading] = useState<boolean>(false);
  const [apiKeyListLoading, setApiKeyListLoading] = useState<boolean>(true);
  const [showModal, setShowModal] = useState<boolean>(false);
  const { callToast } = useContext(ToastContext) as ToastInterface;
  const [apiKeyList, setApiKeyList] = useState<Record<string, any>[]>([]);
  const [apiKey, setApiKey] = useState<string>('');
  const [showDisableConfirmModal, setShowDisableConfirmModal] = useState<boolean>(false);
  const idRef = useRef<string>('');

  const handleDisableApiKey = () => {
    console.log(idRef.current);
    handleShowConfirmKeyModal();
  };

  const handleShowConfirmKeyModal = (id = '') => {
    idRef.current = id;
    setShowDisableConfirmModal(!showDisableConfirmModal);
  };
  const handleShowModal = () => {
    setShowModal(!showModal);
    setApiKey('');
  };

  const copyKey = async (id: string) => {
    try {
      await copyToClipboard(id);
      callToast(ToastTypes.SUCCESS, 'API key copied!');
    } catch (error) {
      callToast(ToastTypes.ERROR, handleErrorMessage(error));
    }
  };

  const getListApiKeys = async () => {
    setApiKeyListLoading(true);
    try {
      const res = await organizationService?.getListApiKeys(TENANT_ID);
      const data = res?.data?.apiKeys?.map((el: Record<string, any>) => {
        el = { ...el, handleFunction: handleShowConfirmKeyModal, copyFunction: copyKey };
        return el;
      });

      setApiKeyList(data);
    } catch (error) {
      callToast(ToastTypes.ERROR, handleErrorMessage(error));
    } finally {
      setTimeout(() => {
        setApiKeyListLoading(false);
      });
    }
  };

  const generateApiKey = async () => {
    setLoading(true);
    try {
      const res = await organizationService?.generateNewApiKey(TENANT_ID, orgName);
      setApiKey(res?.data?.apiKey);
      const obj = {
        authorizedApis: 'ALL',
        apiKey: res?.data?.apiKey,
        handleFunction: handleShowConfirmKeyModal,
        copyFunction: copyKey,
      };
      apiKeyList.push(obj);
      setApiKeyList([...apiKeyList]);
      callToast(ToastTypes.SUCCESS, 'API key added successfully.');
    } catch (error) {
      handleShowModal();
      callToast(ToastTypes.ERROR, handleErrorMessage(error));
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    getListApiKeys();
  }, []);

  return apiKeyListLoading ? (
    <div className={` ${styles.loader}`}>
      {' '}
      <LoadingScreen />
    </div>
  ) : (
    <>
      <div className={` ${styles.apiKeys}`}>
        <h1>API keys</h1>
        <p>Your secret API keys are listed below. Please note that we do not display your secret API keys again after you generate them.</p>
        <p>
          Do not share your API key with others, or expose it in the browser or other client-side code. In order to protect the security of your account, OpenAI may also
          automatically disable any API key that we&apos;ve found has leaked publicly.
        </p>
        <p>
          Enable tracking to see usage per API key on the <a href="/usage">Usage page</a>.
        </p>
        <div className={` ${styles.apiKeys__table}`}>
          <TableCompoWithoutCheckBox reviewRows={apiKeyList} headCells={ApiKeysHeadCell} type="api-keys" />
        </div>
        <CustomButton className={'button'} buttonText="Create new secret key‍" handleFunc={handleShowModal} disabled={loading} />

        <h3>Default organization</h3>
        <p>If you belong to multiple organizations, this setting controls which organization is used by default when making requests with the API keys above.</p>

        <p>Personal</p>
        {/* it should be dropdown */}
        <Input type="text" placeholder="Personal" inputName="brandName" register={register} />
        <p>
          Note: You can also specify which organization to use for each API request. See <a href="/docs/api-reference/authentication">Authentication</a> to learn more.
        </p>
      </div>
      <CustomModal maxWidth="600px" showModal={showModal} handleShowModal={handleShowModal}>
        <ApiKeyGenerateModal apiKey={apiKey} handleShowModal={handleShowModal} generateApiKey={generateApiKey} loading={loading} copyKey={copyKey} />
      </CustomModal>
      <ConfirmKeyDisableModal showModal={showDisableConfirmModal} handleShowModal={handleShowConfirmKeyModal} handleDisableApiKey={handleDisableApiKey} />
    </>
  );
};

export default ApiKeys;
