import React from 'react';
import styles from './Card.module.scss';
import upArrow from 'assets/images/arrow-up.svg';
import downArrow from 'assets/images/arrow-down-red.svg';
import dot from 'assets/images/dots-vertical.svg';
import ApexChartArea from 'component/ApexChartArea';
import { REACT_APP_CUSTOM_SITE_PASSWORD, REACT_APP_CUSTOM_URL } from 'constants/constants';
import CustomButton from 'component/CustomButton';
import { copyToClipboard } from 'utils/utils';
import NoDataPlaceHolder from 'component/noDataPlaceholder/NoPlaceHolder';
interface CardProps {
  title?: string;
  value?: number | string;
  showDots?: boolean;
  showGain?: boolean;
  showLoss?: boolean;
  showGraph?: boolean;
  copySite?: boolean;
}
const Card = ({ title = 'Total Scans', value, showDots = false, showGain = false, showLoss = false, showGraph = false, copySite = false }: CardProps) => {
  return (
    <div className={`cardDesign  ${styles.card}`}>
      <div className={` flex justifySpaceBetween alignCenter`}>
        <p>{title}</p>
        {showDots && <img src={dot} alt="menu" role="cursor" />}
      </div>

      <div>
        <div className={` flex justifySpaceBetween alignCenter`}>
          <div>
            {value ? <h2>{value}</h2> : showGraph ? <h2>{value}</h2> : <NoDataPlaceHolder message="No data to show in selected time period" showOnlyMessage />}
            {showGain && (
              <div className={` ${styles.percentValue}`}>
                <img src={upArrow} alt="menu" />
                15%
                <span> last month</span>
              </div>
            )}
            {showLoss && (
              <div className={` ${styles.percentValue} ${styles.redColor}`}>
                <img src={downArrow} alt="menu" />
                10%
                <span> last month</span>
              </div>
            )}
          </div>
          {showGraph && (
            <div className={`${styles.chart}`}>
              <ApexChartArea />{' '}
            </div>
          )}
        </div>
      </div>
      {copySite && (
        <div className={` ${styles.copySection} flex justifySpaceBetween alignCenter`}>
          <div>
            <h2>{REACT_APP_CUSTOM_URL ?? ''}</h2>
            <p>Password: {REACT_APP_CUSTOM_SITE_PASSWORD ?? ''}</p>
          </div>
          <div>
            <CustomButton id="copy-button" buttonText="Copy to clipboard" className="button outline lg gray" handleFunc={() => copyToClipboard(REACT_APP_CUSTOM_URL ?? '')} />
          </div>
        </div>
      )}
    </div>
  );
};

export default Card;
