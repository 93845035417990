import { API_END_POINTS, REACT_APP_PUBLIC_ORGANIZATION_URL } from 'constants/constants';
// import UseApiService from './apiService';
import axios from 'axios';

class OrganizationService {
  registerTenants(orgName: string) {
    const body = {
      url: `${REACT_APP_PUBLIC_ORGANIZATION_URL}${API_END_POINTS.TENANTS_REGISTER}`,
      data: { orgName },
    };
    return axios.post(body.url, body.data);
  }

  getTenant(id: string) {
    const body = {
      url: `${REACT_APP_PUBLIC_ORGANIZATION_URL}${API_END_POINTS.TENANTS}/${id}`,
    };
    return axios.get(body.url);
  }

  generateNewApiKey(id: string, orgName: string) {
    const body = {
      url: `${REACT_APP_PUBLIC_ORGANIZATION_URL}${API_END_POINTS.TENANTS}/${id}${API_END_POINTS.API_KEYS}`,
      data: {
        orgName,
      },
    };
    return axios.post(body.url, body.data);
  }

  getListApiKeys(id: string) {
    const body = {
      url: `${REACT_APP_PUBLIC_ORGANIZATION_URL}${API_END_POINTS.TENANTS}/${id}${API_END_POINTS.API_KEYS}`,
    };
    return axios.get(body.url);
  }

  disableApiKey(id: string) {
    const body = {
      url: `${REACT_APP_PUBLIC_ORGANIZATION_URL}${API_END_POINTS.TENANTS}/${id}${API_END_POINTS.API_KEYS}`,
    };
    return axios.delete(body.url);
  }
}

export default OrganizationService;
