import * as React from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableRow from '@mui/material/TableRow';
import EnhancedTableHead from './EnhancedTableHead';
import edit from 'assets/images/edit.svg';
import { useState } from 'react';
import style from './TableCompoWithCheckBox.module.scss';
import { camelCaseToWords, capitalizeFirstLetter, dateFormat } from 'utils/utils';
import NoDataPlaceHolder from 'component/noDataPlaceholder/NoPlaceHolder';
import ModalTable from 'component/ModalTable';
import helpIcon from '../../../assets/images/info-icon.svg';
import { ModalTableData } from './tableData';
import trash from '../../../assets/images/trash.svg';
import eyeIcon from '../../../assets/images/eye-open.svg';
import eyeCrossIcon from '../../../assets/images/eye-close.svg';
import copyIcon from '../../../assets/images/copy-icon.svg';
interface TableCompoWithoutCheckBoxProps {
  reviewRows: Record<string, any>[];
  handleSelectedName?: (a: Record<string, any>) => void;
  customGroup?: boolean;
  headCells: Record<string, any>[];
  handleShowModal?: () => void;
  customName?: boolean;
  allUploadedFiles?: (a: string) => void;
  measurementEditable?: boolean;
  handleOnChange?: (e: React.ChangeEvent<HTMLInputElement>, a: string) => void;
  gender?: string;
  type?: string;
}

const TableCompoWithoutCheckBox = ({
  reviewRows = [],
  handleSelectedName,
  customGroup = false,
  headCells,
  handleShowModal,
  customName = false,
  allUploadedFiles,
  measurementEditable,
  handleOnChange,
  gender,
  type = 'custom',
}: TableCompoWithoutCheckBoxProps) => {
  // const [tableData] = useState<Record<string, any>[]>(reviewRows);
  const [showModalTable, setShowModalTable] = useState<boolean>(false);
  const [id, setId] = useState<string>();

  // const [sortType, setSortType] = useState<SortType>(SortType.ASCENDING);

  // const sortColumns = (type: SortType) => {
  //   if (type === SortType.ASCENDING) {
  //     tableData.sort((a: any, b: any) => b.value - a.value);
  //     setTableData([...tableData]);
  //     setSortType(SortType.DESCENDING);
  //   } else {
  //     tableData.sort((a: any, b: any) => a.value - b.value);
  //     setTableData([...tableData]);
  //     setSortType(SortType.ASCENDING);
  //   }
  // };

  // const handleOnChange = (e: React.ChangeEvent<HTMLInputElement>) => {
  //   if (+e.target.value < 0) {
  //     return;
  //   }
  //   const modifiedData = tableData.map((el) => {
  //     if (el.id === editId) {
  //       el.value = +e.target.value;
  //     }
  //     return el;
  //   });
  //   setTableData([...modifiedData]);
  // };
  // console.log(tableData);
  const handleModal = (id: any) => {
    setShowModalTable(true);
    setId(id);
  };

  return (
    <>
      <TableContainer className="tableWithOutCheck">
        <Table aria-labelledby="tableTitle">
          <EnhancedTableHead rowCount={reviewRows?.length} headCells={headCells} allToggle={false} handleToggleAll={() => console.log(1)} withoutCheckBox={true} />
          {reviewRows?.length > 0 ? (
            type === 'custom' ? (
              <TableBody>
                {reviewRows?.map((row) => {
                  const labelId = `enhanced-table-checkbox-${row?.id}`;
                  return customGroup ? (
                    <TableRow hover role="checkbox" tabIndex={-1} key={row.id}>
                      <TableCell component="td" id={labelId} role="cursor" scope="row" padding="none" onClick={() => handleSelectedName?.(row)}>
                        <div className="flex alignCenter">
                          <p>{row?.profile?.userName || '-'}</p>
                        </div>
                      </TableCell>
                      <TableCell component="td" id={labelId} scope="row" padding="none">
                        <div className="flex alignCenter">
                          <p>{row?.profile?.userEmail || '-'}</p>
                        </div>
                      </TableCell>
                      <TableCell component="td" id={labelId} scope="row" padding="none">
                        <div className="flex alignCenter justifyCenter">
                          <span className={`${style.chip} ${row?.profile?.gender === 'female' ? style.chipRed : ``}`}>{capitalizeFirstLetter(row?.profile?.gender)}</span>
                        </div>
                      </TableCell>
                      <TableCell component="td" id={labelId} scope="row" padding="none">
                        <div className="flex alignCenter justifyCenter date">
                          <p>{Object.keys(row?.measurements).length || 0}</p>
                        </div>
                      </TableCell>
                      <TableCell component="td" id={labelId} scope="row" padding="none">
                        <div className="flex alignCenter date">
                          <p>{dateFormat(row?.profile?.scannedAt)}</p>
                        </div>
                      </TableCell>
                      <TableCell component="td" id={labelId} scope="row" padding="none">
                        {customName ? (
                          <div className="flex alignCenter">
                            <p className={style.note}>{row?.notes?.notes || '-'}</p>
                          </div>
                        ) : (
                          <div className="tableAction flex alignCenter justifyCenter">
                            <a
                              className="link"
                              onClick={() => {
                                handleShowModal?.();
                                handleSelectedName?.(row);
                              }}
                            >
                              Upload
                            </a>
                          </div>
                        )}
                      </TableCell>

                      <TableCell component="td" id={labelId} scope="row" padding="none">
                        <div role="cursor" className={style.action}>
                          {/* <img src={trash} alt="trash" /> */}
                          <img
                            src={edit}
                            alt="edit"
                            onClick={() => {
                              handleShowModal?.();
                              handleSelectedName?.(row);
                              allUploadedFiles?.(row?.id);
                              handleSelectedName?.(row);
                            }}
                          />
                        </div>
                      </TableCell>
                    </TableRow>
                  ) : (
                    <TableRow hover role="checkbox" tabIndex={-1} key={row.id} sx={{ cursor: 'pointer' }}>
                      <TableCell component="th" id={labelId} scope="row" padding="none">
                        <div className="flex alignCenter">
                          <h5 className="flex alignCenter">
                            {camelCaseToWords(row?.name ?? '')}
                            <span className={style.infoIcon} onClick={() => handleModal(row?.id)}>
                              <img src={helpIcon} alt="icon" />
                            </span>
                          </h5>
                        </div>
                      </TableCell>

                      <TableCell>
                        <input
                          className={`${measurementEditable ? `show-border` : ``}`}
                          type={'number'}
                          value={row?.value ?? 0}
                          readOnly={!measurementEditable}
                          onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                            handleOnChange?.(e, row?.name);
                          }}
                        />
                        cm
                      </TableCell>
                    </TableRow>
                  );
                })}
              </TableBody>
            ) : (
              <TableBody>
                {reviewRows?.map((el) => (
                  <TableRow key={el.apiKey}>
                    <TableCell>Secret Key</TableCell>
                    <TableCell>
                      <div className="flex alignCenter">
                        {id === el?.apiKey ? el?.apiKey : `${el?.apiKey?.substring(0, 21)}... `}{' '}
                        <span className={`flex alignCenter ${style.eyeIcon}`}>
                          {id === el?.apiKey ? <img src={eyeCrossIcon} alt="eye" onClick={() => setId('')} /> : <img src={eyeIcon} alt="eye" onClick={() => setId(el?.apiKey)} />}
                          <span onClick={() => el?.copyFunction(el.apiKey)}>
                            <img src={copyIcon} alt="copy" onClick={() => setId('')} />
                          </span>
                        </span>
                      </div>
                    </TableCell>
                    <TableCell>{el?.authorizedApis}</TableCell>
                    <TableCell>
                      <span onClick={() => el?.handleFunction?.(el.apiKey)} role="cursor">
                        <img src={trash} alt="trash" />
                      </span>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            )
          ) : (
            <TableBody>
              <TableRow>
                <TableCell colSpan={headCells.length}>
                  <NoDataPlaceHolder />
                </TableCell>
              </TableRow>
            </TableBody>
          )}
        </Table>
      </TableContainer>

      {type === 'custom' &&
        reviewRows?.map((el: Record<string, any>, index) => {
          return (
            <React.Fragment key={index}>
              {el?.id === id && (
                <ModalTable
                  open={showModalTable}
                  setShowModalTable={setShowModalTable}
                  image={gender ? ModalTableData[gender][el?.name]?.path : ''}
                  title={gender ? ModalTableData[gender][el?.name]?.title : ''}
                  desc={gender ? ModalTableData[gender][el?.name]?.desc : ''}
                />
              )}
            </React.Fragment>
          );
        })}
    </>
  );
};

export default TableCompoWithoutCheckBox;
