import { hasCustomAddOnFlag } from 'constants/constants';
import { AUTH_TYPE } from 'enum/enum';
import { AuthContextInterface } from 'interface/interface';
import React, { useEffect, useState } from 'react';
import interceptor from 'services/interceptor';
import { JwtTokenValidate, getLocalStorageData, removeLocalStorage } from 'utils/utils';

export const AuthContext = React.createContext<AuthContextInterface | null>(null);

const AuthContextProvider = ({ children }: { children: React.ReactNode }) => {
  const [auth, setAuth] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(true);
  const [userDetails, setUserDetails] = useState<any>();
  const [isInternalUser, setIsInternalUser] = useState<boolean>(false);
  const [hasCustomAddon, setHasCustomAddon] = useState<boolean>(getLocalStorageData(hasCustomAddOnFlag) ?? false);
  const [orgName, setOrgName] = useState<string>('Personal');

  const handleAuth = (type: AUTH_TYPE) => {
    if (type === AUTH_TYPE.LOGIN) {
      setAuth(true);
    } else if (type === AUTH_TYPE.LOGOUT) {
      setAuth(false);
      setIsInternalUser(false);
      removeLocalStorage('JWTToken');
      removeLocalStorage('InternalJWTToken');
      removeLocalStorage('hasCustomAddon');
    }
  };

  const handleToken = () => {
    const decodedInternalToken = JwtTokenValidate(getLocalStorageData('InternalJWTToken'));
    const decodedToken = JwtTokenValidate(getLocalStorageData('JWTToken'));

    if (decodedInternalToken) {
      if (decodedInternalToken?.properties?.is_internal) {
        setIsInternalUser(true);
      }
    }

    if (decodedToken) {
      setUserDetails(decodedToken?.properties);
      handleAuth(AUTH_TYPE.LOGIN);
    } else {
      handleAuth(AUTH_TYPE.LOGOUT);
    }
    setLoading(false);
  };
  useEffect(() => {
    interceptor(handleAuth);
    handleToken();
  }, []);
  return (
    <AuthContext.Provider
      value={{ auth, setAuth, handleAuth, loading, userDetails, setUserDetails, isInternalUser, setIsInternalUser, hasCustomAddon, setHasCustomAddon, orgName, setOrgName }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export default AuthContextProvider;
