import React from 'react';
import styles from './Analytics.module.scss';
import ApexChart from 'component/ApexChart';
// import Card from 'component/card/Card';
import MapCompo from 'component/mapCompo/MapCompo';
import { createCountryData, formatNumber, getPercentageArray } from 'utils/utils';
import CountryUsersProgress from 'component/countryUsersProgress/CountryUsersProgress';
import ProductProgressGraph from 'component/productsProgressGraph/ProductProgressGraph';
import { AnalyticsChildProps } from 'interface/interface';
import NoDataPlaceHolder from 'component/noDataPlaceholder/NoPlaceHolder';
// import AnalyticsCard from 'component/analyticsCard/AnalyticsCard';
// import { Metrics } from 'enum/enum';
import AnalyticSizingCards from 'component/analyticSizingCards/AnalyticSizingCards';
import AnalyticCardContainer from 'component/analyticCardContainer/AnalyticCardContainer';
import { Metrics } from 'enum/enum';
import AnalyticsCard from 'component/analyticsCard/AnalyticsCard';
import AnalyticGlobalCards from 'component/analyticGlobalCards/AnalyticGlobalCards';

const AnalyticsChild = ({
  usersList,
  datePickerValue,
  noOfSizingSuggestions,
  productDetails,
  measurementSuccessData,
  scanStartedData,
  reScanCount,
  confirmationRate,
}: AnalyticsChildProps) => {
  const countryData = createCountryData(usersList);
  const { total, percentageArray } = getPercentageArray(countryData);

  return (
    <>
      <div className={`${styles.mapMain} dflex`}>
        <header className="dflex justifySpaceBetween alignCenter">
          <h2>Active users right now</h2>
          <span>Real-time report</span>
        </header>
        <div className={`${styles.map}`}>
          <MapCompo countryData={countryData} />
        </div>
        <div className={`${styles.mapList}`}>
          <h2>{formatNumber(total)}</h2>
          {percentageArray?.map((el) => (
            <CountryUsersProgress key={el?.id} countryName={el?.name} value={el?.percentageVal} countryCode={el?.code?.toLowerCase()} />
          ))}
        </div>
      </div>

      <div className={`${styles.cardList} dflex`}>
        <div className={`${styles.card}`}>
          <AnalyticsCard title={Metrics.TOTAL_SCANS} value={usersList?.length} />
        </div>
        <div className={`${styles.card}`}>
          <AnalyticsCard title={Metrics.SIZING_SUGGESTIONS} value={noOfSizingSuggestions} />
        </div>
        <div className={`${styles.card}`}>
          <AnalyticsCard title={Metrics.SWAN_POWERED_SALES} value={productDetails?.totalSales?.totalOrderCount ?? 0} />
        </div>
      </div>
      <AnalyticCardContainer title="Sizing Metrics">
        <AnalyticSizingCards
          noOfSizingSuggestions={noOfSizingSuggestions}
          usersList={usersList}
          datePickerValue={datePickerValue}
          measurementSuccessData={measurementSuccessData}
          confirmationRate={confirmationRate}
          reScanCount={reScanCount}
          scanStartedData={scanStartedData}
          productDetails={productDetails}
        />
      </AnalyticCardContainer>
      <AnalyticCardContainer title="Global Metrics">
        <AnalyticGlobalCards
          noOfSizingSuggestions={noOfSizingSuggestions}
          usersList={usersList}
          datePickerValue={datePickerValue}
          measurementSuccessData={measurementSuccessData}
          confirmationRate={confirmationRate}
          reScanCount={reScanCount}
          scanStartedData={scanStartedData}
          productDetails={productDetails}
        />
      </AnalyticCardContainer>
      <div className={` ${styles.graphBox}`}>
        <header>
          <h2>Daily Scans</h2>
        </header>
        <article className={` ${styles.chart}`}>{<ApexChart data={usersList ?? []} datePickerValue={datePickerValue} />}</article>
      </div>
      <div className={`dflex ${styles.graphList}`}>
        <div className={` ${styles.leftBox}`}>
          <div className={` ${styles.graphBox}`}>
            <header>
              <h2>Products supported Swan</h2>
              <p>{productDetails?.productsSupported?.percentage ?? 0}% of products on your store are live with Swan</p>
            </header>
            {Object.keys(productDetails)?.length > 0 ? (
              <ProductProgressGraph productsSupported={productDetails?.productsSupported} />
            ) : (
              <NoDataPlaceHolder message="No data to show in selected time period" showOnlyMessage />
            )}
          </div>
        </div>
        <div className={` ${styles.leftBox}`}>
          <div className={` ${styles.graphBox}`}>
            <header>
              <h2>Recent activity</h2>
            </header>
            <div className={` ${styles.emailMain}`}>
              {usersList?.length > 0 ? (
                <div className={` ${styles.emailList}`}>
                  {usersList?.map((user: any) =>
                    user?.properties?.email ? (
                      <div className={` ${styles.emailList__list}`} key={user?.id}>
                        <p>{user?.properties?.email}</p>
                      </div>
                    ) : (
                      <div className={` ${styles.emailList__list}`} key={user?.id}>
                        <p>Anonymous</p>
                      </div>
                    )
                  )}
                </div>
              ) : (
                <NoDataPlaceHolder message="No data to show in selected time period" showOnlyMessage />
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default AnalyticsChild;
