import React from 'react';
import { FormControl, MenuItem, Select } from '@mui/material';
import { DATE_PICKER_VALUE } from 'enum/enum';
import styles from './DateFilter.module.scss';
import calender from '../../assets/images/custom-date.svg';
import CustomButton from 'component/CustomButton';

interface DateFilterProps {
  setDatePickerValue: (a: DATE_PICKER_VALUE) => void;
  datePickerValue: DATE_PICKER_VALUE;
  handleShowModal: () => void;
  startDate: string;
  endDate: string;
}
const DateFilter = ({ setDatePickerValue, datePickerValue, handleShowModal, startDate, endDate }: DateFilterProps) => {
  return (
    <div className={`flex alignCenter ${styles.dataFilter}`}>
      {
        <FormControl fullWidth>
          <Select
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            onChange={(e: any) => {
              setDatePickerValue(e.target.value);
            }}
            value={datePickerValue !== DATE_PICKER_VALUE.CUSTOM ? datePickerValue : `${startDate}-${endDate}`}
          >
            <MenuItem className="hidden" value={`${startDate}-${endDate}`}>
              {startDate} - {endDate}
            </MenuItem>
            <MenuItem value={DATE_PICKER_VALUE.TODAY}>Today</MenuItem>
            <MenuItem value={DATE_PICKER_VALUE.TWENTY_FOUR_HOURS}>Last 24 hours</MenuItem>
            <MenuItem value={DATE_PICKER_VALUE.SEVEN_DAYS}>Last 7 days</MenuItem>
            <MenuItem value={DATE_PICKER_VALUE.FOURTEEN_DAYS}>Last 14 days</MenuItem>
            <MenuItem value={DATE_PICKER_VALUE.THIRTY_DAYS}>Last 30 Days</MenuItem>
            <MenuItem value={DATE_PICKER_VALUE.THIS_MONTH}>This Month</MenuItem>
          </Select>
        </FormControl>
      }{' '}
      {/* condition for datePickerValue === DATE_PICKER_VALUE.CUSTOM */}
      <CustomButton
        buttonImg={calender}
        className={`button ${datePickerValue == DATE_PICKER_VALUE.CUSTOM && `active`}`}
        buttonText="Custom"
        handleFunc={handleShowModal}
      ></CustomButton>
    </div>
  );
};

export default DateFilter;
