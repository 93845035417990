import React from 'react';
import styles from './Home.module.scss';
import banner from 'assets/images/home-icon.svg';
import arrow from 'assets/images/arrow-orange.svg';
import arrowBlue from 'assets/images/arrow-blue.svg';
import card1 from 'assets/images/home-card.png';
import card2 from 'assets/images/home-card-2.png';
const Home = () => {
  return (
    <>
      <div className={`${styles.main}`}>
        <div className={`flex justifySpaceBetween  ${styles.main__header}`}>
          <div>
            <h2>Welcome back, Eoin!</h2>
            <p>
              Let&rsquo;s launch Swan on <span>clothingco.co</span>
            </p>
          </div>

          <div className={`${styles.banner}`}>
            <img src={banner} alt="banner" />
          </div>
        </div>
        <div className={`${styles.getStarted}`}>
          <div className={`${styles.getStartedRow} dflex`}>
            <div className={`${styles.getStartedCol}`}>
              <div className={`${styles.getStarted__box}`}>
                <span className={`${styles.getStarted__chip}`}>NEW</span>
                <h3> Launch Try-on with a single click</h3>
                <span className={`flex ${styles.getStarted__start}`}>
                  START <img src={arrow} alt="icon" />
                </span>
                <figure>
                  <img src={card1} alt="icon" />
                </figure>
              </div>
            </div>
            <div className={`${styles.getStartedCol}`}>
              <div className={`${styles.getStarted__box}`}>
                <h3>Onboard sizing in 10 mins</h3>
                <span className={`flex ${styles.getStarted__start}`}>
                  START <img src={arrow} alt="icon" />
                </span>
                <figure>
                  <img src={card2} alt="icon" />
                </figure>
              </div>
            </div>
            <div className={`${styles.getStartedCol}`}>
              <div className={`${styles.getStarted__box}`}>
                <ul>
                  <li>
                    <h3>
                      <p>Track your success</p>
                      <span>
                        Go to Analytics <img src={arrowBlue} alt="icon" />
                      </span>
                    </h3>
                  </li>
                  <li>
                    <h3>
                      <p>Boost engagement</p>
                      <span>
                        Go to Resource Library <img src={arrowBlue} alt="icon" />{' '}
                      </span>
                    </h3>
                  </li>
                  <li>
                    <h3>
                      <p>Contact support</p>
                      <span>
                        Book a call <img src={arrowBlue} alt="icon" />{' '}
                      </span>
                    </h3>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default Home;
