import React from 'react';
import styles from './FileUploaded.module.scss';
import 'react-circular-progressbar/dist/styles.css';
import { FileUploadData } from 'interface/interface';

import File from 'component/file/File';
// import film from '../../assets/images/film.svg';
// import cloud from '../../assets/images/upload-cloud-blue.svg';
interface FileUploadProps {
  files: FileUploadData[];
  fileFilter?: (a: string) => void;
  handleDeleteFile?: (id: string) => Promise<void>;
}

const FileUploaded = ({ files, fileFilter, handleDeleteFile }: FileUploadProps) => {
  return (
    <>
      <div className={`${styles.uploaded}`}>
        {files?.map((data: FileUploadData) => data?.show && <File data={data} key={data?.id} fileFilter={fileFilter} handleDeleteFile={handleDeleteFile} />)}
      </div>
    </>
  );
};

export default FileUploaded;
