import { ModalContentType, SHOW_CASE } from 'enum/enum';
import { ApprovedNotes, FileUploadData, GroupData, MeasurementContextInterface, TechPackData } from 'interface/interface';
import React, { ReactNode, useState, createContext } from 'react';

export const MeasurementContext = createContext<MeasurementContextInterface | null>(null);

const MeasurementContextProvider = ({ children }: { children: ReactNode }) => {
  const [showCase, setShowCase] = useState<SHOW_CASE>(SHOW_CASE.ALL_PRODUCTS);
  const [loading, setLoading] = useState<boolean>(false);
  const [childLoading, setChildLoading] = useState<boolean>(false);
  const [collectionLoading, setCollectionLoading] = useState<boolean>(false);
  const [allProducts, setAllProducts] = useState<any[]>([]);
  const [productPageInfo, setProductPageInfo] = useState<Record<string, string>>({});
  const [allCollections, setAllCollections] = useState<Record<string, any>>({});
  const [buttonDisabled, setButtonDisabled] = useState<boolean>(false);
  const [progress, setProgress] = useState<number>(1);
  const [techPackRes, setTechPackRes] = useState<TechPackData | undefined>();
  const [showModal, setShowModal] = useState<boolean>(false);
  const [uploadFileModal, setUploadFileModal] = useState<boolean>(false);
  const [files, setFiles] = useState<FileUploadData[]>([]);
  const [approvedNotes, setApprovedNotes] = useState<ApprovedNotes>({ notes: '', approved: false, noteId: '' });
  const [multiFiles, setMultiFiles] = useState<FileUploadData[]>([]);
  const [groups, setGroups] = useState<GroupData[]>([]);
  const [modalContentType, setModalContentType] = useState<ModalContentType>(ModalContentType.CREATE);
  const [value, setValue] = useState<string>('1');
  const [groupDetail, setGroupDetail] = useState<GroupData>({ id: '', name: '', expand: false });
  const [selectedProduct, setSelectedProduct] = useState<string[]>([]);
  const [sizesMatch, setSizesMatch] = useState<boolean>(false);
  const [childButtonDisabled, setChildButtonDisabled] = useState<boolean>(false);
  const values = {
    showCase,
    setShowCase,
    loading,
    setLoading,
    childLoading,
    setChildLoading,
    collectionLoading,
    setCollectionLoading,
    allProducts,
    setAllProducts,
    productPageInfo,
    setProductPageInfo,
    allCollections,
    setAllCollections,
    buttonDisabled,
    setButtonDisabled,
    progress,
    setProgress,
    techPackRes,
    setTechPackRes,
    showModal,
    setShowModal,
    files,
    setFiles,
    approvedNotes,
    setApprovedNotes,
    multiFiles,
    setMultiFiles,
    groups,
    setGroups,
    modalContentType,
    setModalContentType,
    value,
    setValue,
    groupDetail,
    setGroupDetail,
    selectedProduct,
    setSelectedProduct,
    sizesMatch,
    setSizesMatch,
    childButtonDisabled,
    setChildButtonDisabled,
    uploadFileModal,
    setUploadFileModal,
  };

  return <MeasurementContext.Provider value={values}>{children}</MeasurementContext.Provider>;
};

export default MeasurementContextProvider;
