// import ApexChartArea from 'component/ApexChartArea';
import styles from './AnalyticsCard.module.scss';
import upArrow from 'assets/images/arrow-up.svg';
import downArrow from 'assets/images/arrow-down-red.svg';
import React from 'react';
import { DATE_PICKER_VALUE } from 'enum/enum';
import ApexChart from 'component/ApexChart';
import NoDataPlaceHolder from 'component/noDataPlaceholder/NoPlaceHolder';

interface AnalyticsCard {
  title: string;
  value: number | string;
  showGain?: boolean;
  showLoss?: boolean;
  showGraph?: boolean;
  data?: any[];
  datePickerValue?: DATE_PICKER_VALUE;
}

const AnalyticsCard = ({ title, value, showGain = false, showLoss = false, showGraph = false, data = [], datePickerValue }: AnalyticsCard) => {
  return (
    <div className={` ${styles.analyticsCard}`}>
      <div>
        <h4>
          {title}
          {showGain && (
            <span className={` ${styles.percentValue}`}>
              <img src={upArrow} alt="menu" />
              15%
            </span>
          )}
          {showLoss && (
            <span className={` ${styles.percentValue} ${styles.redColor}`}>
              <img src={downArrow} alt="menu" />
              10%
            </span>
          )}
        </h4>

        {value === 0 ? <NoDataPlaceHolder message="No data to show in selected time period" showOnlyMessage /> : <h3>{value}</h3>}
      </div>
      {showGraph ? <ApexChart data={data ?? []} height={110} datePickerValue={datePickerValue} showYaxis={false} /> : <></>}
    </div>
  );
};

export default AnalyticsCard;
