import React from 'react';
import { InputProps } from 'interface/interface';
import styles from './Input.module.scss';

const Input = ({ placeholder, afterIcon, preLabel, readOnly, type = 'text', label = '', errorMessage = '', register, inputName, textLimit }: InputProps) => {
  return (
    <div className={`dflex ${styles.input} ${preLabel && styles.prLabelInput}`}>
      <label> {label} </label>
      {type !== 'textarea' && (
        <div className={`${styles.inputWrapper} ${preLabel && styles.prLabelWrapper}`}>
          {preLabel && <div className={`dflex ${styles.prLabel}`}>{preLabel}</div>}
          <input readOnly={readOnly} type={type} placeholder={placeholder} {...register(inputName, { required: true })} />
          {afterIcon && <img className={` ${styles.afterIcon}`} src={afterIcon} alt="icon" />}
        </div>
      )}
      {type === 'textarea' && (
        <>
          <textarea maxLength={200} placeholder={placeholder} {...register(inputName, { required: true })}></textarea>
          <div className={`dflex ${styles.belowText}`}>{textLimit} characters left</div>
        </>
      )}
      <div className={` ${styles.error}`}>{errorMessage}</div>
    </div>
  );
};

export default Input;
