import React from 'react';
import loaderImage from '../assets/images/loader.gif';

const LoadingScreen = ({ size = 100, height = true }: { size?: number; height?: boolean }) => {
  return (
    <div className={`flex alignCenter justifyCenter ${height ? 'h-100' : ''} loader`}>
      <img src={loaderImage} alt="Swan logo" width={size} />
    </div>
  );
};

export default LoadingScreen;
