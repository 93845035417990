import React from 'react';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
interface DateRangePickerProps {
  setStartDate: (a: Date) => void;
  startDate: Date;
  setEndDate: (a: Date) => void;
  endDate: Date;
}
const DateRangePicker = ({ setStartDate, startDate, setEndDate, endDate }: DateRangePickerProps) => {
  return (
    <>
      <DatePicker
        dateFormat="yyyy/MM/dd"
        selectsRange
        startDate={startDate}
        endDate={endDate}
        onChange={(update: any) => {
          setStartDate(update[0]);
          setEndDate(update[1]);
        }}
      />
      {/* <DatePicker dateFormat="yyyy/MM/dd" selected={endDate} onChange={(date: Date) => setEndDate(date)} selectsEnd startDate={startDate} endDate={endDate} minDate={startDate} /> */}
    </>
  );
};

export default DateRangePicker;
