import AnkleGirthMale from '../../../assets/modal-images/Ankle-Girth-M.jpg';
import ArmLengthMale from '../../../assets/modal-images/Arm-Length-M.jpg';
import BackWidthMale from '../../../assets/modal-images/Back-Width-M.jpg';
import BustGirthMale from '../../../assets/modal-images/Bust-Girth-M.jpg';
import CalfGirthMale from '../../../assets/modal-images/Calf-Girth-M.jpg';
import ElbowGirthMale from '../../../assets/modal-images/Elbow-Girth-M.jpg';
import ForeArmGirthMale from '../../../assets/modal-images/Forearm-Girth-M.jpg';
import HeadGirth from '../../../assets/modal-images/Head-Hirth.jpg';
import HipGirthMale from '../../../assets/modal-images/Hip-Girth-M.jpg';
import HighHipGirthMale from '../../../assets/modal-images/High-Hip-Girth-M.jpg';
import InseamToFloorMale from '../../../assets/modal-images/Inseam to Floor-M.jpg';
import InseamToAnkleMale from '../../../assets/modal-images/Inseam-to-Ankle-M.jpg';
import KneeGirthMale from '../../../assets/modal-images/knee-girth-M.jpg';
import NapeToWaistMale from '../../../assets/modal-images/Nape-to-Waist-M.jpg';
// import NeckGirthMale from '../../../assets/modal-images/Neck-Girth-M.jpg';
import NeckBaseGirthMale from '../../../assets/modal-images/Neck-Base-Girth-M.jpg';
import ShoulderWidthMale from '../../../assets/modal-images/Shoulder-Width-M.jpg';
import ThighGirthMale from '../../../assets/modal-images/Thigh-Girth-M.jpg';
import TorsoLengthMale from '../../../assets/modal-images/Torso-Length-M.jpg';
import TotalRiseMale from '../../../assets/modal-images/Total-Rise-M.jpg';
import UnderBustGirthMale from '../../../assets/modal-images/Underbust-Girth-M.jpg';
import UpperArmCircumMale from '../../../assets/modal-images/Upper-Arm-Circumference-M.jpg';
import WaistGirthMale from '../../../assets/modal-images/Waist-Girth-M.jpg';
import WristGirthMale from '../../../assets/modal-images/Wrist-Girth-M.jpg';
import AnkleGirthFemale from '../../../assets/modal-images/Ankle-Girth-F.jpg';
import ArmLengthFemale from '../../../assets/modal-images/Arm-Length-F.jpg';
import BackWidthFemale from '../../../assets/modal-images/Back-Width-F.jpg';
import BustGirthFemale from '../../../assets/modal-images/Bust-Girth-F.jpg';
import CalfGirthFemale from '../../../assets/modal-images/Calf-Girth-F.jpg';
import ElbowGirthFemale from '../../../assets/modal-images/Elbow-Girth-F.jpg';
import ForeArmGirthFemale from '../../../assets/modal-images/Forearm-Girth-F.jpg';
import HipGirthFemale from '../../../assets/modal-images/Hip-Girth-F.jpg';
import HighHipGirthFemale from '../../../assets/modal-images/High-Hip-Girth-F.jpg';
import InseamToFloorFemale from '../../../assets/modal-images/Inseam to Floor-F.jpg';
import InseamToAnkleFemale from '../../../assets/modal-images/Inseam-to-Ankle-F.jpg';
import KneeGirthFemale from '../../../assets/modal-images/knee-girth-F.jpg';
import NapeToWaistFemale from '../../../assets/modal-images/Nape-to-Waist-F.jpg';
import NeckGirthFemale from '../../../assets/modal-images/Neck-Girth-F.jpg';
import NeckBaseGirthFemale from '../../../assets/modal-images/Neck-Base-Girth-F.jpg';
import ShoulderWidthFemale from '../../../assets/modal-images/Shoulder-Width-F.jpg';
import ThighGirthFemale from '../../../assets/modal-images/Thigh-Girth-F.jpg';
import TorsoLengthFemale from '../../../assets/modal-images/Torso-Length-F.jpg';
import TotalRiseFemale from '../../../assets/modal-images/Total-Rise-F.jpg';
import UnderBustGirthFemale from '../../../assets/modal-images/Underbust-Girth-F.jpg';
import UpperArmCircumFemale from '../../../assets/modal-images/Upper-Arm-Circumference-F.jpg';
import WaistGirthFemale from '../../../assets/modal-images/Waist-Girth-F.jpg';
import WristGirthFemale from '../../../assets/modal-images/Wrist-Girth-F.jpg';
import AcrossShoulderFemale from '../../../assets/modal-images/Across-Shoulder-F.jpg';
import AcrossShoulderMale from '../../../assets/modal-images/Across-Shoulder-M.jpg';

export const reviewHeadCells = [
  {
    id: '0001',
    label: 'Measurement',
  },
  {
    id: '0002',
    label: 'Value',
  },
];

export const reviewRows1 = [
  {
    id: 1,
    measurement: 'Neck',
    // Url: 'Neck',
    value: 40,
  },
  {
    id: 2,
    measurement: 'Shoulders',
    // Url: 'Neck',
    value: 70,
  },
  {
    id: 3,
    measurement: 'Waist',
    // Url: 'Neck',
    value: 70,
  },
  {
    id: 4,
    measurement: 'High Waist',
    // Url: 'Neck',
    value: 75,
  },
  {
    id: 5,
    measurement: 'Upper Bust',
    // Url: 'Neck',
    value: 97,
  },
  {
    id: 6,
    measurement: 'Bust',
    // Url: 'Neck',
    value: 103,
  },
  {
    id: 7,
    measurement: 'Lower Bust',
    // Url: 'Neck',
    value: 101,
  },
];
export const reviewRows2 = [
  {
    id: 1,
    measurement: 'Neck',
    // Url: 'Neck',
    value: 40,
  },
  {
    id: 2,
    measurement: 'Shoulders',
    // Url: 'Neck',
    value: 70,
  },
  {
    id: 3,
    measurement: 'Waist',
    // Url: 'Neck',
    value: 70,
  },
  {
    id: 4,
    measurement: 'High Waist',
    // Url: 'Neck',
    value: 75,
  },
  {
    id: 5,
    measurement: 'Upper Bust',
    // Url: 'Neck',
    value: 97,
  },
  {
    id: 6,
    measurement: 'Bust',
    // Url: 'Neck',
    value: 103,
  },
  {
    id: 7,
    measurement: 'Lower Bust',
    // Url: 'Neck',
    value: 101,
  },
];

export const groupHeadCell = [
  {
    label: 'Name',
    id: '17c1329a-2f61-4636-99c8-cbbab1fc90d790334',
  },
  {
    label: 'Email',
    id: '17c1329a-2f61-4636-99c8-cbbab1fc90d7',
  },
  {
    id: 'b7c12b83-2e8d-4945-8d5b-c3d3966f268a',
    label: 'Gender',
    center: true,
  },
  {
    id: '49df43d4-823e-4ad6-9f6e-2b44e3d780e13',
    label: 'Measurement Taken',
    center: true,
  },
  {
    id: 'f1984e65-2a41-4cb6-8349-2193b7333f3b005',
    label: 'Last Measured',
  },
  {
    id: 'f1984e65-2a41-4cb6-8349-2193b7333f3b555',
    label: 'Notes',
  },
  {
    id: 'f1984e65-2a41-4cb6-8349-2193b7333f3b55599',
    label: '',
  },
];
export const ApiKeysHeadCell = [
  {
    label: 'Name',
    id: '17c1329a-2f61-4636-99c8-cbbab1fc90d790334',
  },
  {
    label: 'Secret Key',
    id: '17c1329a-2f61-4636-99c8-cbbab1fc90d7',
  },
  {
    id: 'b7c12b83-2e8d-4945-8d5b-c3d3966f268a',
    label: 'Permissions',
  },

  {
    id: 'f1984e65-2a41-4cb6-8349-2193b7333f3b005',
    label: '',
  },
];
export const customGroupRows = [
  {
    id: 1,
    email: 'andrew@7till8.com',
    gender: 'Male',
    orderLinked: 'Mens Surf Fullsuit',
    measured: '22 Jan 2022',
    notes: 'Prefers a fit which...',
    name: 'Andrew Park',
  },
  {
    id: 2,
    email: 'neal@getswan.co',
    gender: 'Male',
    orderLinked: 'Mens Front Zip Jacket',
    measured: '20 Jan 2022',
    notes: 'wants extra room',
    name: 'Neal Mc Namara',
  },

  {
    id: 3,
    email: 'eoin@getswan.co',
    gender: 'Male',
    orderLinked: '',
    measured: '24 Jan 2022',
    notes: '',
    name: 'Eoin Cambay',
  },
  {
    id: 4,
    email: 'customer@gmail.com',
    gender: 'Male',
    orderLinked: 'Surf Fullsuit',
    measured: '26 Jan 2022',
    notes: '',
    name: 'Customer',
  },
  {
    id: 5,
    email: 'customer@gmail.com',
    gender: 'Female',
    orderLinked: 'Womens Short Jane',
    measured: '18 Jan 2022',
    notes: 'Love her Hooded...',
    name: 'Customer',
    female: true,
  },
  {
    id: 6,
    email: 'customer@gmail.com',
    gender: 'Male',
    orderLinked: 'Shipped',
    measured: '28 Jan 2022',
    notes: '',
    name: 'Customer',
    shipped: true,
  },
];

export const calibrationHeadCell = [
  // {
  //   id: 'b9e1c9a5-5fda-420c-871f-3a839cc33f133',
  //   label: 'Customer',
  // },
  {
    label: 'Email',
    id: '17c1329a-2f61-4636-99c8-cbbab1fc90d7',
  },
  {
    id: 'b7c12b83-2e8d-4945-8d5b-c3d3966f268a',
    label: 'Gender',
  },

  {
    id: 'f1984e65-2a41-4cb6-8349-2193b7333f3b005',
    label: 'Scanned on',
  },
  {
    id: '49df43d4-823e-4ad6-9f6e-2b44e3d780e13',
    label: 'Upload measurements',
    center: true,
  },
  {
    id: 'f1984e65-2a41-4cb6-8349-2193b7333f3b555',
    label: '',
  },
];

export const calibrationRows = [
  {
    id: 1,
    email: 'andrew@7till8.com',
    gender: 'Male',
    scannedOn: '22 Jan 2022',
    measurements: 'Upload',
    name: 'Andrew Park',
  },
  {
    id: 2,
    email: 'neal@getswan.co',
    gender: 'Male',
    scannedOn: '20 Jan 2022',
    measurements: 'Upload',
    name: 'Neal Mc Namara',
  },

  {
    id: 3,
    email: 'eoin@getswan.co',
    gender: 'Male',
    scannedOn: '24 Jan 2022',
    measurements: 'Upload',
    name: 'Eoin Cambay',
  },
  {
    id: 4,
    email: 'customer@gmail.com',
    gender: 'Male',
    scannedOn: '26 Jan 2022',
    measurements: 'Upload',
    name: 'Customer',
  },
  {
    id: 5,
    email: 'customer@gmail.com',
    gender: 'Female',
    scannedOn: '18 Jan 2022',
    measurements: 'Upload',
    name: 'Customer',
    female: true,
  },
  {
    id: 6,
    email: 'customer@gmail.com',
    gender: 'Male',
    scannedOn: '28 Jan 2022',
    measurements: 'Upload',
    name: 'Customer',
  },
];

export const ModalTableData: any = {
  male: {
    ankle: {
      title: 'Ankle Girth',
      path: AnkleGirthMale,
      desc: 'Circumference around the base of the neck, from the 7th Cervical Vertebra, over the neck shoulder points and the centre front neck point, and back to the nape point.',
    },
    armLength: {
      title: 'Arm Length',
      path: ArmLengthMale,
      desc: 'Circumference around the base of the neck, from the 7th Cervical Vertebra, over the neck shoulder points and the centre front neck point, and back to the nape point.',
    },
    armLengthStraight: {
      title: 'Arm Length Straight',
      path: ArmLengthMale,
      desc: 'Circumference around the base of the neck, from the 7th Cervical Vertebra, over the neck shoulder points and the centre front neck point, and back to the nape point.',
    },
    bicep: {
      title: 'Bicep', //Image not available
      path: UpperArmCircumMale,
      desc: 'Circumference around the base of the neck, from the 7th Cervical Vertebra, over the neck shoulder points and the centre front neck point, and back to the nape point.',
    },
    calf: {
      title: 'Calf Girth',
      path: CalfGirthMale,
      desc: 'Circumference around the base of the neck, from the 7th Cervical Vertebra, over the neck shoulder points and the centre front neck point, and back to the nape point.',
    },
    bust: {
      title: 'Bust Girth',
      path: BustGirthMale,
      desc: 'Circumference around the base of the neck, from the 7th Cervical Vertebra, over the neck shoulder points and the centre front neck point, and back to the nape point.',
    },
    forearm: {
      title: 'Forearm Girth',
      path: ForeArmGirthMale,
      desc: 'Circumference around the base of the neck, from the 7th Cervical Vertebra, over the neck shoulder points and the centre front neck point, and back to the nape point.',
    },
    head: {
      title: 'Head Girth',
      path: HeadGirth,
      desc: 'Circumference around the base of the neck, from the 7th Cervical Vertebra, over the neck shoulder points and the centre front neck point, and back to the nape point.',
    },
    hip: {
      title: 'Hip Girth',
      path: HipGirthMale,
      desc: 'Circumference around the base of the neck, from the 7th Cervical Vertebra, over the neck shoulder points and the centre front neck point, and back to the nape point.',
    },
    inseamToAnkle: {
      title: 'Inseam To Ankle',
      path: InseamToAnkleMale,
      desc: 'Circumference around the base of the neck, from the 7th Cervical Vertebra, over the neck shoulder points and the centre front neck point, and back to the nape point.',
    },
    inseam: {
      title: 'Inseam',
      path: InseamToAnkleMale,
      desc: 'Circumference around the base of the neck, from the 7th Cervical Vertebra, over the neck shoulder points and the centre front neck point, and back to the nape point.',
    },
    knee: {
      title: 'Knee Girth',
      path: KneeGirthMale,
      desc: 'Circumference around the base of the neck, from the 7th Cervical Vertebra, over the neck shoulder points and the centre front neck point, and back to the nape point.',
    },
    neckCirc: {
      title: 'Neck Girth',
      path: NeckBaseGirthMale,
      desc: 'Circumference around the base of the neck, from the 7th Cervical Vertebra, over the neck shoulder points and the centre front neck point, and back to the nape point.',
    },
    shoulder: {
      title: 'Shoulder Width',
      path: ShoulderWidthMale,
      desc: 'Circumference around the base of the neck, from the 7th Cervical Vertebra, over the neck shoulder points and the centre front neck point, and back to the nape point.',
    },
    thigh: {
      title: 'Thigh Girth',
      path: ThighGirthMale,
      desc: 'Circumference around the base of the neck, from the 7th Cervical Vertebra, over the neck shoulder points and the centre front neck point, and back to the nape point.',
    },
    torso: {
      title: 'Torso Length',
      path: TorsoLengthMale,
      desc: 'Circumference around the base of the neck, from the 7th Cervical Vertebra, over the neck shoulder points and the centre front neck point, and back to the nape point.',
    },
    underBust: {
      title: 'UnderBust Girth',
      path: UnderBustGirthMale,
      desc: 'Circumference around the base of the neck, from the 7th Cervical Vertebra, over the neck shoulder points and the centre front neck point, and back to the nape point.',
    },
    wrist: {
      title: 'Wrist Girth',
      path: WristGirthMale,
      desc: 'Circumference around the base of the neck, from the 7th Cervical Vertebra, over the neck shoulder points and the centre front neck point, and back to the nape point.',
    },
    back: {
      title: 'Back Width',
      path: BackWidthMale,
      desc: 'Circumference around the base of the neck, from the 7th Cervical Vertebra, over the neck shoulder points and the centre front neck point, and back to the nape point.',
    },
    elbow: {
      title: 'Elbow Girth',
      path: ElbowGirthMale,
      desc: 'Circumference around the base of the neck, from the 7th Cervical Vertebra, over the neck shoulder points and the centre front neck point, and back to the nape point.',
    },
    highHip: {
      title: 'High-Hip Girth',
      path: HighHipGirthMale,
      desc: 'Circumference around the base of the neck, from the 7th Cervical Vertebra, over the neck shoulder points and the centre front neck point, and back to the nape point.',
    },
    inseamToFloor: {
      title: 'Inseam To Floor',
      path: InseamToFloorMale,
      desc: 'Circumference around the base of the neck, from the 7th Cervical Vertebra, over the neck shoulder points and the centre front neck point, and back to the nape point.',
    },
    napeToWaist: {
      title: 'Nape To Waist',
      path: NapeToWaistMale,
      desc: 'Circumference around the base of the neck, from the 7th Cervical Vertebra, over the neck shoulder points and the centre front neck point, and back to the nape point.',
    },
    neckBase: {
      title: 'Neck-Base Girth',
      path: NeckBaseGirthMale,
      desc: 'Circumference around the base of the neck, from the 7th Cervical Vertebra, over the neck shoulder points and the centre front neck point, and back to the nape point.',
    },
    totalRise: {
      title: 'Total-Rise',
      path: TotalRiseMale,
      desc: 'Circumference around the base of the neck, from the 7th Cervical Vertebra, over the neck shoulder points and the centre front neck point, and back to the nape point.',
    },
    upperArm: {
      title: 'Upper-Arm Circumference',
      path: UpperArmCircumMale,
      desc: 'Circumference around the base of the neck, from the 7th Cervical Vertebra, over the neck shoulder points and the centre front neck point, and back to the nape point.',
    },
    acrossBackShoulder: {
      title: 'Across Back Shoulder',
      path: AcrossShoulderMale,
      desc: 'Circumference around the base of the neck, from the 7th Cervical Vertebra, over the neck shoulder points and the centre front neck point, and back to the nape point.',
    },
    waist: {
      title: 'Waist Girth',
      path: WaistGirthMale,
      desc: 'Circumference around the base of the neck, from the 7th Cervical Vertebra, over the neck shoulder points and the centre front neck point, and back to the nape point.',
    },
    seat: {
      title: 'Seat',
      path: TotalRiseMale,
      desc: 'Circumference around the base of the neck, from the 7th Cervical Vertebra, over the neck shoulder points and the centre front neck point, and back to the nape point.',
    },
  },
  female: {
    ankle: {
      title: 'Ankle Girth',
      path: AnkleGirthFemale,
      desc: 'Circumference around the base of the neck, from the 7th Cervical Vertebra, over the neck shoulder points and the centre front neck point, and back to the nape point.',
    },
    acrossBackShoulder: {
      title: 'Across Back Shoulder',
      path: AcrossShoulderFemale,
      desc: 'Circumference around the base of the neck, from the 7th Cervical Vertebra, over the neck shoulder points and the centre front neck point, and back to the nape point.',
    },
    armLength: {
      title: 'Arm Length',
      path: ArmLengthFemale,
      desc: 'Circumference around the base of the neck, from the 7th Cervical Vertebra, over the neck shoulder points and the centre front neck point, and back to the nape point.',
    },
    armLengthStraight: {
      title: 'Arm Length Straight',
      path: ArmLengthFemale,
      desc: 'Circumference around the base of the neck, from the 7th Cervical Vertebra, over the neck shoulder points and the centre front neck point, and back to the nape point.',
    },
    bicep: {
      title: 'Bicep', //Image not available
      path: UpperArmCircumFemale,
      desc: 'Circumference around the base of the neck, from the 7th Cervical Vertebra, over the neck shoulder points and the centre front neck point, and back to the nape point.',
    },
    calf: {
      title: 'Calf Girth',
      path: CalfGirthFemale,
      desc: 'Circumference around the base of the neck, from the 7th Cervical Vertebra, over the neck shoulder points and the centre front neck point, and back to the nape point.',
    },
    bust: {
      title: 'Bust Girth',
      path: BustGirthFemale,
      desc: 'Circumference around the base of the neck, from the 7th Cervical Vertebra, over the neck shoulder points and the centre front neck point, and back to the nape point.',
    },
    seat: {
      title: 'Seat',
      path: TotalRiseFemale,
      desc: 'Circumference around the base of the neck, from the 7th Cervical Vertebra, over the neck shoulder points and the centre front neck point, and back to the nape point.',
    },
    forearm: {
      title: 'Forearm Girth',
      path: ForeArmGirthFemale,
      desc: 'Circumference around the base of the neck, from the 7th Cervical Vertebra, over the neck shoulder points and the centre front neck point, and back to the nape point.',
    },
    head: {
      title: 'Head Girth',
      path: HeadGirth,
      desc: 'Circumference around the base of the neck, from the 7th Cervical Vertebra, over the neck shoulder points and the centre front neck point, and back to the nape point.',
    },
    hip: {
      title: 'Hip Girth',
      path: HipGirthFemale,
      desc: 'Circumference around the base of the neck, from the 7th Cervical Vertebra, over the neck shoulder points and the centre front neck point, and back to the nape point.',
    },
    inseamToAnkle: {
      title: 'Inseam To Ankle',
      path: InseamToAnkleFemale,
      desc: 'Circumference around the base of the neck, from the 7th Cervical Vertebra, over the neck shoulder points and the centre front neck point, and back to the nape point.',
    },
    inseam: {
      title: 'Inseam',
      path: InseamToAnkleFemale,
      desc: 'Circumference around the base of the neck, from the 7th Cervical Vertebra, over the neck shoulder points and the centre front neck point, and back to the nape point.',
    },
    knee: {
      title: 'Knee Girth',
      path: KneeGirthFemale,
      desc: 'Circumference around the base of the neck, from the 7th Cervical Vertebra, over the neck shoulder points and the centre front neck point, and back to the nape point.',
    },
    neckCirc: {
      title: 'Neck Girth',
      path: NeckGirthFemale,
      desc: 'Circumference around the base of the neck, from the 7th Cervical Vertebra, over the neck shoulder points and the centre front neck point, and back to the nape point.',
    },
    shoulder: {
      title: 'Shoulder Width',
      path: ShoulderWidthFemale,
      desc: 'Circumference around the base of the neck, from the 7th Cervical Vertebra, over the neck shoulder points and the centre front neck point, and back to the nape point.',
    },
    thigh: {
      title: 'Thigh Girth',
      path: ThighGirthFemale,
      desc: 'Circumference around the base of the neck, from the 7th Cervical Vertebra, over the neck shoulder points and the centre front neck point, and back to the nape point.',
    },
    torso: {
      title: 'Torso Length',
      path: TorsoLengthFemale,
      desc: 'Circumference around the base of the neck, from the 7th Cervical Vertebra, over the neck shoulder points and the centre front neck point, and back to the nape point.',
    },
    underBust: {
      title: 'UnderBust Girth',
      path: UnderBustGirthFemale,
      desc: 'Circumference around the base of the neck, from the 7th Cervical Vertebra, over the neck shoulder points and the centre front neck point, and back to the nape point.',
    },
    wrist: {
      title: 'Wrist Girth',
      path: WristGirthFemale,
      desc: 'Circumference around the base of the neck, from the 7th Cervical Vertebra, over the neck shoulder points and the centre front neck point, and back to the nape point.',
    },
    back: {
      title: 'Back Width',
      path: BackWidthFemale,
      desc: 'Circumference around the base of the neck, from the 7th Cervical Vertebra, over the neck shoulder points and the centre front neck point, and back to the nape point.',
    },
    elbow: {
      title: 'Elbow Girth',
      path: ElbowGirthFemale,
      desc: 'Circumference around the base of the neck, from the 7th Cervical Vertebra, over the neck shoulder points and the centre front neck point, and back to the nape point.',
    },
    highHip: {
      title: 'High-Hip Girth',
      path: HighHipGirthFemale,
      desc: 'Circumference around the base of the neck, from the 7th Cervical Vertebra, over the neck shoulder points and the centre front neck point, and back to the nape point.',
    },
    inseamToFloor: {
      title: 'Inseam To Floor',
      path: InseamToFloorFemale,
      desc: 'Circumference around the base of the neck, from the 7th Cervical Vertebra, over the neck shoulder points and the centre front neck point, and back to the nape point.',
    },
    napeToWaist: {
      title: 'Nape To Waist',
      path: NapeToWaistFemale,
      desc: 'Circumference around the base of the neck, from the 7th Cervical Vertebra, over the neck shoulder points and the centre front neck point, and back to the nape point.',
    },
    neckBase: {
      title: 'Neck-Base Girth',
      path: NeckBaseGirthFemale,
      desc: 'Circumference around the base of the neck, from the 7th Cervical Vertebra, over the neck shoulder points and the centre front neck point, and back to the nape point.',
    },
    totalRise: {
      title: 'Total-Rise',
      path: TotalRiseFemale,
      desc: 'Circumference around the base of the neck, from the 7th Cervical Vertebra, over the neck shoulder points and the centre front neck point, and back to the nape point.',
    },
    upperArm: {
      title: 'Upper-Arm Circumference',
      path: UpperArmCircumFemale,
      desc: 'Circumference around the base of the neck, from the 7th Cervical Vertebra, over the neck shoulder points and the centre front neck point, and back to the nape point.',
    },
    waist: {
      title: 'Waist Girth',
      path: WaistGirthFemale,
      desc: 'Circumference around the base of the neck, from the 7th Cervical Vertebra, over the neck shoulder points and the centre front neck point, and back to the nape point.',
    },
  },
};
