import React, { useContext } from 'react';
import { Tabs, Tab } from '@mui/material';
import styles from './CategoriesAndProducts.module.scss';
import { ModalContentType, PRODUCT_TYPE, TabsValue, URL_TYPE } from 'enum/enum';
import NoDataPlaceHolder from 'component/noDataPlaceholder/NoPlaceHolder';
import TableCompoWithStartingCheckBox from 'component/table/tableCompoWithCheckBox/TableCompoWithStartingCheckBox';
import AddIcon from 'assets/images/plus-blue.svg';
import GroupCompo from 'component/groupCompo/GroupCompo';
import InfiniteScrollComponent from 'component/InfiniteScrollComponent';
import GroupList from 'component/groupList/GroupList';
import { MeasurementContext } from 'context/MeasurementContext';
import { MeasurementChildContext } from 'context/MeasurementChildContext';
import { MeasurementContextInterface } from 'interface/interface';
import LoadingScreen from 'component/LoadingScreen';

const CategoriesAndProducts = () => {
  const {
    productPageInfo,
    value,
    loading,
    allProducts,
    selectedProduct,
    setSelectedProduct,
    childButtonDisabled,
    collectionLoading,
    allCollections,
    childLoading,
    groups,
    groupDetail,
  } = useContext(MeasurementContext) as MeasurementContextInterface;
  const { fetchProducts, fetchCollectionProducts, fetchGroupProducts, handleTabsChange, handleShowModal, handleProductOnBoard, productAllToggle, handleToggleAll, buttonDisabled } =
    useContext(MeasurementChildContext) as any;
  const handleProductPageApi = (type: PRODUCT_TYPE, collectionId = '') => {
    if (productPageInfo?.hasNextPage) {
      if (type === PRODUCT_TYPE.PRODUCTS) {
        fetchProducts(productPageInfo?.endCursor, URL_TYPE.NEXT);
      } else if (type === PRODUCT_TYPE.COLLECTIONS) {
        fetchCollectionProducts({ collectionId, requestUrl: productPageInfo?.endCursor, expand: true });
      } else {
        fetchGroupProducts(groupDetail?.id, productPageInfo?.endCursor, URL_TYPE.NEXT);
      }
    } else {
      console.log('no data');
    }
  };

  return (
    <div className={`${styles.products}`}>
      <div className={`${styles.products__tabs}`}>
        <div className={`${styles.products__header}`}>
          {/* <div className={`${styles.products__top} flex alignCenter justifySpaceBetween`}>
            <h4>
              Products <span>240</span>
            </h4>

            <button className="button">
              <img src={AddIcon} alt="+" />
              New Group
            </button>
          </div> */}
          <nav className="productTabs dflex alignCenter justifySpaceBetween">
            <aside>
              <Tabs value={value} onChange={handleTabsChange}>
                <Tab label="All products" value={TabsValue.ONE} />
                <Tab label="Shopify Categories" value={TabsValue.TWO} />
                <Tab label="Groups" value={TabsValue.THREE} />
                <Tab value={TabsValue.FOUR} style={{ display: 'none' }} />
              </Tabs>
            </aside>
            <button className="button" onClick={() => handleShowModal(ModalContentType.CREATE)}>
              <img src={AddIcon} alt="+" />
              New Group
            </button>
            {/* <div className={styles.products__search}>
              <img src={SearchIcon} alt="+" />
              <input type="text" placeholder="Shirts" />
            </div> */}
          </nav>
        </div>

        {(() => {
          switch (value) {
            case TabsValue.ONE: {
              return (
                <div className={`${styles.productWrapper}  ${loading && styles.loader} `}>
                  {loading && allProducts?.length === 0 ? (
                    <div className={`${styles.products__loader} dflex alignCenter justifyCenter w-full`}>
                      <LoadingScreen />
                    </div>
                  ) : (
                    <>
                      <TableCompoWithStartingCheckBox
                        data={allProducts}
                        loading={loading}
                        showToggle={false}
                        selectedProduct={selectedProduct}
                        setSelectedProduct={setSelectedProduct}
                        handleShowModal={handleShowModal}
                        handleProductOnBoard={(value, id) => handleProductOnBoard(value, id, PRODUCT_TYPE.PRODUCTS)}
                        allToggle={productAllToggle}
                        buttonDisabled={childButtonDisabled}
                        handleToggleAll={(flag) => handleToggleAll(flag, PRODUCT_TYPE.PRODUCTS)}
                      />

                      <InfiniteScrollComponent functionToCall={() => handleProductPageApi(PRODUCT_TYPE.PRODUCTS)} data={allProducts} />
                    </>
                  )}
                </div>
              );
            }
            case TabsValue.TWO: {
              return (
                <div className={`${styles.productWrapper} `}>
                  {collectionLoading && Object.keys(allCollections)?.length === 0 ? (
                    <div className={`${styles.products__loader} dflex alignCenter justifyCenter w-full`}>
                      <LoadingScreen />
                    </div>
                  ) : Object.keys(allCollections)?.length > 0 ? (
                    <div className="w-full">
                      {Object.keys(allCollections)?.map((el: any) => {
                        const data = allCollections?.[el];
                        return (
                          <React.Fragment key={data?.value?.id}>
                            <div className={`flex alignCenter justifySpaceBetween ${styles.groupData} habshb`}>
                              <h5>{data?.value?.title}</h5>
                              <div className={`flex alignCenter ${styles.groupData__button} ${childLoading ? styles.isDisabled : ''}`}>
                                <span onClick={() => fetchCollectionProducts({ collectionId: el, expand: !data?.expand })}>{data?.expand ? 'Collapse table' : 'Expand table'}</span>
                              </div>
                            </div>
                            {data?.expand && (
                              <div className={`${value === TabsValue.TWO ? styles.productTableScroll : ''}`}>
                                {childLoading && data?.child?.length === 0 ? (
                                  <div className={`dflex alignCenter justifyCenter w-full ${styles.loaderStyle}`}>
                                    <LoadingScreen />
                                  </div>
                                ) : (
                                  <div className={`${styles.productWrapper__table} w-full`}>
                                    <TableCompoWithStartingCheckBox
                                      setSelectedProduct={setSelectedProduct}
                                      selectedProduct={selectedProduct}
                                      loading={childLoading}
                                      data={data?.child}
                                      showToggle={false}
                                      // title={data?.value?.title}
                                      buttonDisabled={childButtonDisabled}
                                      handleShowModal={handleShowModal}
                                      allToggle={data?.toggleAll}
                                      handleProductOnBoard={(value, id) => handleProductOnBoard(value, id, PRODUCT_TYPE.COLLECTIONS, data?.value?.collectionId)}
                                      handleToggleAll={(flag) => handleToggleAll(flag, PRODUCT_TYPE.COLLECTIONS, data?.value?.collectionId)}
                                    />
                                    <InfiniteScrollComponent functionToCall={() => handleProductPageApi(PRODUCT_TYPE.COLLECTIONS, el)} data={data?.child} />
                                  </div>
                                )}
                              </div>
                            )}
                          </React.Fragment>
                        );
                      })}
                    </div>
                  ) : (
                    <div className="dflex alignCenter justifyCenter w-full">
                      <NoDataPlaceHolder message="No Collection found in this list" />
                    </div>
                  )}
                </div>
              );
            }
            case TabsValue.THREE: {
              return (
                <>
                  {loading ? (
                    <div className={`${styles.products__loader} ${styles.isGruopLoading} w-full`}>
                      <LoadingScreen height={false} />
                    </div>
                  ) : (
                    <div className={`${styles.productWrapper}`}>
                      <GroupList groups={groups} handleTabsChange={handleTabsChange} handleShowModal={handleShowModal} buttonDisabled={buttonDisabled} />
                    </div>
                  )}
                </>
              );
            }
            default: {
              return <GroupCompo handleProductPageApi={() => handleProductPageApi(PRODUCT_TYPE.GROUPS)} />;
            }
          }
        })()}
      </div>
    </div>
  );
};

export default CategoriesAndProducts;
