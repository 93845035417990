import { GroupData } from 'interface/interface';
import React, { useState } from 'react';
import style from './GroupCard.module.scss';
import dot from 'assets/images/dots-vertical.svg';
import { ModalContentType, TabsValue } from 'enum/enum';
import DropdownMenu from 'component/dropDownMenu/DropdownMenu';
interface GroupCardProps {
  group: GroupData;
  handleTabsChange: (event: React.SyntheticEvent, newValue: string, id?: string) => void;
  handleShowModal: (a?: ModalContentType, id?: string) => void;
  buttonDisabled: boolean;
}

const GroupCard = ({ group, handleTabsChange, handleShowModal, buttonDisabled }: GroupCardProps) => {
  const [showMenu, setShowMenu] = useState(false);
  return (
    <div role="cursor" className={style.groupCard} onClick={(e) => handleTabsChange(e, TabsValue.FOUR, group?.id)}>
      <span>{group?.name}</span>

      <DropdownMenu menuState={setShowMenu}>
        <div className={style.groupCard__menu}>
          <img src={dot} onClick={() => setShowMenu(!showMenu)} alt="menu" />
          {showMenu && (
            <ul className={buttonDisabled ? style.isDisabled : ''} onClick={() => setShowMenu(false)}>
              <li onClick={(e) => handleTabsChange(e, TabsValue.FOUR, group?.id)}>Open</li>
              <li onClick={() => handleShowModal(ModalContentType.EDIT, group?.id)}>Edit</li>
              <li onClick={() => handleShowModal(ModalContentType.DELETE, group?.id)}>Delete</li>
            </ul>
          )}
        </div>
      </DropdownMenu>
    </div>
  );
};

export default GroupCard;
