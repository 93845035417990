import React from 'react';
import { Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions, Button } from '@mui/material';
import DateRangePicker from './DateRangePicker';
import { DATE_PICKER_VALUE } from 'enum/enum';
interface CustomDialogModalProps {
  open: boolean;
  handleShowModal: () => void;
  setStartDate: (a: Date) => void;
  startDate: Date;
  setEndDate: (a: Date) => void;
  endDate: Date;
  setDatePickerValue: (a: DATE_PICKER_VALUE) => void;
  fetchAllList: (a: boolean) => Promise<void>;
}
const CustomDialogModal = ({ open, handleShowModal, setStartDate, setEndDate, endDate, startDate, setDatePickerValue, fetchAllList }: CustomDialogModalProps) => {
  return (
    <>
      <Dialog className="custom-dialog" open={open} onClose={handleShowModal} aria-labelledby="responsive-dialog-title">
        <DialogTitle id="responsive-dialog-title">{'Select date range'}</DialogTitle>
        <DialogContent>
          <DialogContentText>
            <DateRangePicker setStartDate={setStartDate} setEndDate={setEndDate} endDate={endDate} startDate={startDate} />
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button autoFocus onClick={handleShowModal}>
            Cancel
          </Button>
          <Button
            onClick={() => {
              handleShowModal();
              setDatePickerValue(DATE_PICKER_VALUE.CUSTOM);
              fetchAllList(true);
            }}
            autoFocus
            className="button-style"
          >
            Apply
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default CustomDialogModal;
